import Swiper, { Navigation, Pagination, EffectCube } from 'swiper';


document.querySelectorAll('.sk-carousel').forEach(carousel => {
  // Onderstaande comment overgenomen vanuit oude tooling
  // TODO: queryselectors op geselecteerde caroussel, ter voorkoming van verwarring met andere caroussels! (zie andere caroussels)

  new Swiper('.swiper-container', {
    modules: [Navigation, Pagination, EffectCube],
    effect: 'cube',
    loop: true,
    pagination: {
      el: '.swiper-pagination',
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
});
