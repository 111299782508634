document.querySelectorAll('.primary__googlereview').forEach(rating => {
  // Configuration ts specific
  let num: any;
  let numAfter: any;
  let numBefore: any;

  // Get numbers
  let node = document.querySelector(".stars__count") as HTMLInputElement;
  let getStuff = node.innerHTML;
  // Target wrapper
  let target = document.querySelector(".stars__wrapper");

  // Some number splitting
  num = getStuff.split("/").shift();
  numBefore = num.split(".").shift();
  numAfter = num.split(".").pop();

  // ..
  for (let i = 1; i <= numBefore; i++) {
    target!.innerHTML += "<div class='st stars__star'></div>";
  }
  if (numBefore < 5 && numAfter >= 5 && numAfter <= 9) {
    target!.innerHTML += "<div class='st stars__star-half'></div>";
  }
});
