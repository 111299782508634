document.addEventListener("DOMContentLoaded", () => {
    document.querySelectorAll('.selection-box_container').forEach(selectionBoxContainer => {
        selectionBoxContainer.querySelectorAll('.selection-box').forEach(item => {
            item.addEventListener('click', () => {
                if (selectionBoxContainer.dataset.multiSelect) {
                    item.toggleAttribute('selected');
                } else {
                    selectionBoxContainer.querySelector('[selected]').removeAttribute('selected');
                    item.setAttribute('selected', '');
                }
            });
        });
    });
});