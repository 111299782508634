import $ from "jquery";

// Default Accordion
$('.accordion__title-wrapper').on('click', ({currentTarget}) => {
    const $item = $(<HTMLElement>currentTarget.parentElement);
    $item.toggleClass('accordion__item--active');
    $item.find('.accordion__content').slideToggle(250);
});

// Single Accordion `showmore`
$('.showmore__wrapper').on('click', ({currentTarget}) => {
    const $item = $(<HTMLElement>currentTarget.parentElement);
    $item.toggleClass('showmore__item--active');
    $item.find('.showmore__content').slideToggle(250);
    let changeText = $('.showmore__target').text();
    $('.showmore__target').text(
        changeText == 'Lees minder' ? 'Lees meer' : 'Lees minder');
});

// Mini accordion (TODO: rewrite to vanilla JS)
$('.showmore__wrapper-left').on('click', ({currentTarget}) => {
    const $item = $(<HTMLElement>currentTarget.parentElement);
    $item.toggleClass('showmore__item--active-left');
    $item.find('.showmore__content').slideToggle(250);
});
