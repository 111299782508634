const breakpoint = 767;

document.querySelectorAll('.header-carousel .header-carousel__content--video, .header-video').forEach((element: HTMLElement) => {
  var video : HTMLVideoElement | null = element.querySelector('video');
  if (video) {
    loadVideo(video, element);

    window.addEventListener('resize', () => {
      if (video) {
        loadVideo(video, element);
      }
    });
  }
});

function loadVideo(video: HTMLVideoElement, parent: HTMLElement) {
  if (window.innerWidth > breakpoint) {
    if (video.querySelectorAll('source').length < 1) {
      var url = parent.dataset.videoUrl;
      var type = parent.dataset.videoType;

      if (url && type) {
        var source = document.createElement('source');
        source.setAttribute('src', url);
        source.setAttribute('type', type);
        video.appendChild(source);
      }
      video.play();
    }
  } else {
    video.querySelectorAll('source').forEach(source => {
      source.remove();
    });
  }
}
