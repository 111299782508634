import React from 'react';
import ReactDOM from 'react-dom';
import CarFavorites from './car-favorites';

const favoritesEl = document.querySelector('.car-favorites');

if (favoritesEl) {
  ReactDOM.render(
    React.createElement(CarFavorites),
    favoritesEl
  );
};
