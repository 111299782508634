export default function FilterMeerTonen() {
    const headers = document.getElementsByClassName("showmore__wrapper"),
          contents = document.getElementsByClassName("showmore__content-revert"),
          targetTonenText = document.getElementsByClassName("showmore__target-revert");

    for (let i = 0; i < headers.length; i++) {
        headers[i].addEventListener("click", () => {
            for (let j = 0; j < contents.length; j++) {
                if (i == j) {
                    contents[j].style.display = contents[j].style.display == "block" ? "none" : "block";
                    targetTonenText[j].innerHTML = contents[j].style.display == "block" ? "Minder tonen" : "Meer tonen";
                    targetTonenText[j].classList.toggle("revert-active");
                } else {
                    // contents[j].style.display = "none";
                }
            }
        });
    }
};


