import React from 'react';
import ReactDOM from 'react-dom';
import Hero from './hero-react';

const wrapperEl = document.querySelector(".searchresult-facets-home-vehicle");
const formEl = wrapperEl ? wrapperEl.querySelector(".facets-home-vehicle") : null;

if (wrapperEl && formEl) {
  const facetsEndpoint = wrapperEl.getAttribute("data-facetsurl");
  const textResults = wrapperEl.getAttribute("data-results");
  const textInputText = wrapperEl.getAttribute("data-input-text");

  const el = document.createElement("div");
  formEl.append(el);

  ReactDOM.render(
    React.createElement(Hero, {
      facetsEndpoint,
      textResults,
      textInputText,
    }),
    el
  );
};
