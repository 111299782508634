import $ from 'jquery';
import 'jquery-ui';

const desktop = 1150;
let mobileMode = false;

// Switch between mobile and desktop logic
if (window.innerWidth < desktop) {
  mobileMode = true;
}
// ---
// // Animations with JQuery-UI
//
// Checkmarks
let checkmarks: JQuery = $('.page--home .third .tr-segment > .tr-segment__content .usp > .checkmark-svg-wrapper, .page--home .third .tr-segment > .tr-segment__content > .columns .checkmark-green');
// checkmarks.css('visibility', 'hidden');

// Tekst links van afbeelding
let textLeft: JQuery = $('.page--home .third .tr-segment > .tr-segment__text:first-child > .tr-segment__text-content');
// textLeft.css('visibility', 'hidden');

// Tekst rechts van afbeelding
let textRight: any = $('.page--home .third .tr-segment > .tr-segment__text:nth-child(2) > .tr-segment__text-content');
// textRight.css('visibility', 'hidden');

// Acties
let centeredContent: any = $('.page--home .third .tr-segment--centered-content > .tr-segment__content > .block-nomargin > div > div > .block-default');
// centeredContent.css('visibility', 'hidden');

// Voordelen
let advantagesText: any = $('.page--home .third .tr-segment--centered-content:not(:first-child) > .tr-segment__content > .columns > .columns__column > .checkmark-green > .checkmark-green__body > p');
// advantagesText.css('visibility', 'hidden');

// Klantbeoordelingen
let customerReviews: any = $('.page--home .third .tr-segment--centered-content:not(:first-child) > .tr-segment__content > .columns--white > .columns__column');
// customerReviews.css('visibility', 'hidden');

const fadeInOnScroll = function(element: JQuery, height: number, effect: string, direction: string, duration: number, delay: number, timeOut: any) {
  if (element) {
    element.each(function(this: any) {
      let currentElement: any = $(this);
      if (currentElement.css('visibility') === 'hidden') {
        let offset: any = currentElement.parent().offset();
        let scrollTop: number | undefined = $(window).scrollTop();
        let windowHeight: number | undefined = $(window).innerHeight();
        if (offset && scrollTop && windowHeight) {
          let bottom_of_element: number = offset.top;
          let bottom_of_window: number | undefined = scrollTop + windowHeight;

          // If the object is completely visible in the window, fade it in
          if (bottom_of_element && bottom_of_window && bottom_of_element + height < bottom_of_window) {
            currentElement.css('visibility', 'visible').hide();
            currentElement.show({ effect: effect, direction: direction, duration: duration });
            duration += delay;

            timeOut = animateWithTimeOut(currentElement, timeOut);
          }
        }
      }
    });
  }
}

const animateWithTimeOut = function(currentElement: any, timeOut: any) {
  if (timeOut) {
    setTimeout(function() {
      currentElement.addClass('animate');
    }, timeOut);
  }

  return timeOut + timeOut;
}

window.scroll(function() {
  if (mobileMode) {
    fadeInOnScroll(textLeft, 200, 'fade', '', 1000, 0, null);
    fadeInOnScroll(textRight, 200, 'fade', '', 1000, 0, null);
  } else {
    fadeInOnScroll(textLeft, 200, 'drop', 'left', 1000, 0, null);
    fadeInOnScroll(textRight, 200, 'drop', 'right', 1000, 0, null);
  }
  fadeInOnScroll(checkmarks, 250, 'fade', '', 500, 1000, 300);
  fadeInOnScroll(centeredContent, 200, 'drop', 'down', 750, 500, null);
  fadeInOnScroll(advantagesText, 200, 'drop', 'down', 1000, 500, null);
  fadeInOnScroll(customerReviews, 200, 'drop', 'down', 750, 500, null);
});

// See homepage
// Remove search query from url
$(function(){
  $('#facetinfo').submit(function(event) {
    // remove soort params if all selected
    const checkboxes = $(this).find('input[name="soort"]:checked');
    const checkboxArray: any[] = [];

    checkboxes.each((j, el) => {
      checkboxArray.push($(el).val());
    });

    if (checkboxArray.includes('occasion') && checkboxArray.includes('nieuw') && checkboxArray.includes('demo')) {
      checkboxes.each(function() {
        if (checkboxArray.includes(checkboxes.val())) {
          $(this).prop('checked', false);
        }
      });
    }

    // remove query param if empty
    const emptyQueryName = $('#querysearch-input-homepage').val();
    if (emptyQueryName == '') {
      $(this).find('input[name="query"]').removeAttr('name');
    }
  });
});
