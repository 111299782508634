import sliderSrcPlpRd from "../sliders-src/slider-src-plp-rd";
import FilterMeerTonen from "./rd-filter--meertonen";
import RdLeesmeer from "../rd-leesmeer/rd-leesmeer";

let loading = false,
    waiting = false;

document.querySelectorAll('input[name="query"]').forEach(input => {
    input.addEventListener('input', () => {
        if (!waiting) {
            waiting = true;
            setTimeout(() => { waiting = false; onFilterChange(); }, querySearchDelay ? querySearchDelay : 500);
        }
    });
});

document.querySelectorAll('.submit-search').forEach(button => {
    button.addEventListener('click', onFilterChange);
});

function initDropdowns() {
    document.querySelectorAll('.sortering-rd select').forEach(button => {
        button.addEventListener('change', onFilterChange);
    });
}
initDropdowns();

function initCheckboxes() {
    document.querySelectorAll('.filter-content-block__items input[type="checkbox"]').forEach(checkbox => {
        checkbox.addEventListener('change', () => {
            onFilterChange();
        });
    });
}

initCheckboxes();

function initSubmitButtons() {
    document.querySelectorAll('.filter-content-block .showmore__content .submit-search').forEach(button => {
        button.addEventListener('click', onFilterChange);
    });
}

function initTags() {
    let tags = document.querySelectorAll('.filter-show-selected_tags button');
    tags.forEach(tag => {
        tag.addEventListener('click', () => {
            if (tag.dataset.keys) {
                tag.dataset.keys.split(',').forEach(key => {
                    document.querySelector(`.filter-content input[name="${key}"], .filter-content select[name="${key}"]`).value = '';
                });
                onFilterChange();
            } else {
                document.querySelector(`input[name="${tag.dataset.key}"][value="${tag.dataset.value}"]`).checked = false;
                onFilterChange();
            }
        });
    });
    if (tags.length > 0) {
        document.querySelector(".filter-show-selected_wis-filters")?.removeAttribute('hidden');
    } else {
        document.querySelector(".filter-show-selected_wis-filters")?.setAttribute('hidden', 'true');
    }
}

initTags();

let desktopSelect = document.querySelector(".filter-show-selected_sortering select");
if (desktopSelect) {
    desktopSelect.addEventListener('change', onFilterChange);
}
let mobileSelect = document.querySelector(".filter-results select");
if (mobileSelect) {
    mobileSelect.addEventListener('change', onFilterChange);
}

function onDesktop() {
    return self.innerWidth > 1140;
}

function onFilterChange() {
    loading = true;
    from = 0;

    getItems((result) => {
        let plpIntro = document.querySelector('.plp-intro');
        if (plpIntro) {
            plpIntro.innerHTML = result.querySelector('.plp-intro').innerHTML;
        }
        let pagingBlock = document.querySelector('#pagingblock');
        if (pagingBlock) {
            pagingBlock.innerHTML = result.querySelector('#pagingblock').innerHTML;
        }
        let categoryTextBlock = document.querySelector('#categoryText');
        if (categoryTextBlock) {
            categoryTextBlock.innerHTML = result.querySelector('#categoryText').innerHTML;
        }
        document.querySelector('.plp-card-grid').innerHTML = result.querySelector('.plp-card-grid').innerHTML;
        document.querySelector('#total-results-desktop').innerHTML = result.querySelector('#total-results-desktop').innerHTML;
        document.querySelector('#total-results-mobile').innerHTML = result.querySelector('#total-results-mobile').innerHTML;
        document.querySelector('.filter-show-selected_tags').innerHTML = result.querySelector('.filter-show-selected_tags').innerHTML;
        document.querySelectorAll('.filter-content-block .showmore__content').forEach(facetContent => {
            facetContent.innerHTML = result.querySelector('#' + facetContent.id).innerHTML;
        });
        initDropdowns();
        initCheckboxes();
        initSubmitButtons();
        initTags();
        sliderSrcPlpRd();
        FilterMeerTonen();
        RdLeesmeer();

        // BlueConic Listener
        const bcReadyEvent = new Event("bcContentReady");
        window.dispatchEvent(bcReadyEvent);

        loading = false;
    });
}

let deleteTagsAnchor = document.querySelector('.filter-show-selected_wis-filters > a');
deleteTagsAnchor?.addEventListener('click', () => {
    document.querySelectorAll('.filter-content-block__items input[type="checkbox"]:checked').forEach(checkbox => checkbox.checked = false);
    document.querySelector('.filter-show-selected_tags').querySelectorAll('*').forEach(tag => tag.remove());
    document.querySelectorAll('input[type="text"], span.sortering-rd > select').forEach(input => {
        input.value = '';
    });
    onFilterChange();
});


function getItems(callback, extraQuery) {
    let url = appendFiltersToUrl(ajaxUrl);

    if (extraQuery) {
        url += extraQuery;
    }

    ajaxGet(url, callback, () => {
    });
}

function appendFiltersToUrl(baseUrl) {
    let params = '', sort;

    document.querySelectorAll('.filter-content-block__items input[type="text"]').forEach(input => {
        if (input.value && input.value != '') {
            params += `&${input.name}=${input.value}`;
        }
    });

    document.querySelectorAll('.filter-content-block__items input[type="checkbox"]').forEach(checkbox => {
        if (checkbox.checked) {
            params += `&${checkbox.name}=${checkbox.value}`;
        }
    });

    document.querySelectorAll('.filter-content-block__items select').forEach(select => {
        if (select.value && select.value != '') {
            params += `&${select.name}=${select.value}`;
        }
    });

    if (onDesktop()) {
        sort = document.querySelector(".filter-show-selected_sortering select");
    } else {
        sort = document.querySelector(".filter-results select");
    }

    if (sort && sort.value && sort.value != '') {
        const sortby = sort.value.split(':');
        params += `&orderby=${sortby[0]}`;
        if (sortby.length > 1) {
            params += `&sortorder=${sortby[1]}`;
        }
    }

    let pageUrl = window.location.origin + window.location.pathname + params.replace('&', '?');
    if (pageUrl != window.location) {
        window.history.pushState({}, document.title, pageUrl);
        document.querySelectorAll('[data-paging]').forEach(pagingLink => {
            pagingLink.href = (pageUrl + (pageUrl.indexOf('?') >= 0 ? '&' : '?') + "from=" + pagingLink.dataset.paging + "#searchresults");
        });
    }
    return baseUrl += params;
}

function ajaxGet(url, callback) {
    const xmlhttp = new XMLHttpRequest();

    xmlhttp.onreadystatechange = () => {
        if (xmlhttp.readyState === XMLHttpRequest.DONE && xmlhttp.status === 200) {
            const result = document.createElement('div');
            result.innerHTML = xmlhttp.responseText.trim();
            callback(result);
        }
    };

    xmlhttp.open('GET', url, true);
    xmlhttp.send();
}

if (typeof perPage !== 'undefined') {
    window.addEventListener('scroll', () => {
        const threshold = document.querySelector(`.plp-card-grid div:nth-last-of-type(${Math.ceil(perPage / 2)})`);
        if (threshold && threshold.getBoundingClientRect().top < window.innerHeight && !loading) {
            loading = true;
            document.querySelector('.spinner').hidden = false;
            getItems((result) => {
                const cards = result.querySelectorAll('.plp-card-grid > div');
                const list = document.querySelector('.plp-card-grid');
                cards.forEach((card) => {
                    list.appendChild(card);
                });
                if (cards.length >= perPage) {
                    loading = false;
                }
                document.querySelector('.spinner').hidden = true;
                from += perPage;
                to = from + perPage;
                updateWindowUrl();
            }, `&from=${from + perPage}`);
        }
    });
}

const toParam = /to=(\d+)/;

function updateWindowUrl() {
    let pageUrl = window.location.href;

    if (pageUrl.includes('&to=')) {
        let currentTo = pageUrl.match(toParam);
        if (currentTo < to) {
            pageUrl.replace(toParam, 'to=' + to);
        }
    } else {
        pageUrl += (pageUrl.includes('?') ? '&' : '?') + 'to=' + to;
    }
    if (pageUrl != window.location.href) {
        window.history.pushState({}, document.title, pageUrl);
        console.log("setting history: " + pageUrl)
    }
}
