import Swiper, { Navigation } from 'swiper';

const swiper = new Swiper(".specialistSwiper", {
    modules: [Navigation],
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        800: {
            slidesPerView: 2,
            spaceBetween: 20,
        },
    },
});