import React from 'react';
import { Facet, IFacetItem, IRangeFacet, ISearchFacet } from './facet';

interface ISelectedFacetsProps {
  clearAll : () => void;
  clear : (facet : Facet, item? : IFacetItem) => void;
  facets : Facet[];
}

interface IFacetState {
  facetExpaned : boolean;
}

export default class SelectedFacets extends React.Component<ISelectedFacetsProps, IFacetState> {
  constructor(props : ISelectedFacetsProps) {
    super(props)

    this.clearAll = this.clearAll.bind(this);
    this.renderClearButton = this.renderClearButton.bind(this);
    this.toggleFacetExpanded = this.toggleFacetExpanded.bind(this);

    this.state = {
      facetExpaned: true,
    };
  }

  public render() : JSX.Element | null {
    const selectedFacets : Facet[] = this.selectedFacets();
    if (!selectedFacets.length) {
      return null;
    }

    return (
      <div className={`facet facet--selected-facets ${this.state.facetExpaned ? "facet--expanded" : "facet--collapsed"}`}>
        <div className="facet__title" onClick={this.toggleFacetExpanded}>Uw gekozen filters</div>
        {this.renderBody()}
      </div>
    )
  }

  private renderBody() : JSX.Element | null {
    if (!this.state.facetExpaned) {
      return null;
    }

    return (
      <React.Fragment>
        {this.renderItems()}
        {this.renderClearButton()}
      </React.Fragment>
    )
  }

  private renderItems() : JSX.Element | null {
    const selectedFacets : Facet[] = this.selectedFacets();
    if (!selectedFacets.length) {
      return null;
    }

    return (
      <ol className="facet__items">
        {selectedFacets.map((facet) => {
          switch (facet.type) {
            case "filter":
              return facet.items.filter((item) => item.selected).sort((a, b) => a.title.localeCompare(b.title)).map((item) => this.renderItem(facet, item))
            case "range":
              return this.renderRangeFacet(facet);
            case "search":
              return this.renderSearchFacet(facet);
          }
        })}
      </ol>
    );
  }

  private renderRangeFacet(facet : IRangeFacet) : JSX.Element {
    const key : string = `facet-${facet.key}`;
    let title : string;
    if (facet.min !== null && facet.max !== null) {
      title = `${facet.title}: ${facet.min} - ${facet.max}`;
    } else if (facet.min !== null) {
      title = `${facet.title}: >= ${facet.min}`;
    } else {
      title = `${facet.title}: <= ${facet.max}`;
    }

    return (
      <li className="facet__item" key={key}>
        <input type="checkbox" id={key} checked={true} onChange={this.props.clear.bind(this, facet)} />
        <label htmlFor={key}>{title}</label>
      </li>
    );
  }

  private renderSearchFacet(facet : ISearchFacet) : JSX.Element {
    const key : string = `facet-${facet.key}`;
    return (
      <li className="facet__item" key={key}>
        <input type="checkbox" id={key} checked={true} onChange={this.props.clear.bind(this, facet)} />
        <label htmlFor={key}>{facet.q}</label>
      </li>
    );
  }

  private renderItem(facet : Facet, item : IFacetItem) : JSX.Element {
    const key : string = `facet-${facet.key}-${item.key}`;
    return (
      <li className="facet__item" key={key}>
        <input type="checkbox" id={key} checked={item.selected} onChange={this.props.clear.bind(this, facet, item)} />
        <label htmlFor={key}>{facet.key === "vestiging" ? item.title.replace(/Oostendorp Auto|Oostendorp Scholten/g,'') : item.title}</label>
      </li>
    );
  }

  private renderClearButton() : JSX.Element | null {
    const selectedFacets : Facet[] = this.selectedFacets();
    if (!selectedFacets.length) {
      return null;
    }

    return (
      <a href="#" className="facet__clear" onClick={this.clearAll}>Wis alle filters</a>
    );
  }

  private selectedFacets() : Facet[] {
    return this.props.facets.filter((facet : Facet) => {
      switch (facet.type) {
        case "filter":
          return facet.items.some((item : IFacetItem) => item.selected);
        case "range":
          return facet.min !== null || facet.max !== null;
        case "search":
          return facet.q.length > 0;
      }
    });
  }

  private toggleFacetExpanded() : void {
    this.setState({facetExpaned: !this.state.facetExpaned});
  }

  private clearAll(event : any) : void {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    this.props.clearAll();
  }
}
