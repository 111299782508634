import React from 'react';
import { Facet, IDropdownFacet, IFacetItem } from './facet';

interface IFacetProps {
  facet : IDropdownFacet;
  onToggle : (item : IFacetItem) => void;
  showExpander : boolean;
  clear : (facet : Facet, item? : IFacetItem) => void;
}

interface IFacetState {
}

export default class FilterFacet extends React.Component<IFacetProps, IFacetState> {
  static defaultProps = {
    clear: {},
  }

  constructor(props : IFacetProps) {
    super(props);

    this.toggleSelected = this.toggleSelected.bind(this);
    this.clearFacet = this.clearFacet.bind(this);
  }

  public render() : JSX.Element {
    return (

        <div className="showmore__item-left showmore__item--active-left" id="showmore-id">
          <div className="filter-content-block__items">
            <span className="sortering-rd">
                {this.renderItems()}
            </span>
          </div>
        </div>

    )
  }

  private toggleSelected(item : IFacetItem) : void {
    this.props.onToggle(item);
  }

  private renderItems() : JSX.Element | null {
    const allOptionName : string = `${this.props.facet.key}-All`;
    return (
        <select name={this.props.facet.key} id="" onChange={this.toggleSelected.bind(this.props.facet)}>
          <div className="facets-home-vehicle__form-facets">
            <div className={`facet facet--filter facet--collapsed"}`}>
              <option className="facet__title" disabled selected
                      hidden>{this.props.facet.title}</option>
            </div>
          </div>
          <option value="" onClick={this.clearFacet}><label
              htmlFor={allOptionName}>Alle {this.props.facet.title}en</label></option>
          {this.props.facet.items.map((item: IFacetItem) => this.renderItem(item))}
        </select>
  )
    ;
  }

  private renderItem(item: IFacetItem): JSX.Element {
    const inputName: string = `facet-${this.props.facet.key}-${item.key}`;
    let itemHasResults: boolean = typeof (item.count) === "number" && item.count > 0;
    return (
          <option id={inputName} className={itemHasResults ? "" : "greyed_out"} name={this.props.facet.key}
                  value={item.key} selected={item.selected} onChange={this.toggleSelected.bind(this, item)} disabled={!itemHasResults} >
            {item.title} {itemHasResults ? <span className="filter-content-block__item_count">({item.count})</span> : ""}
          </option>
    );
  }

  private clearFacet(event : any) : void {
    this.props.facet.items.forEach(item => { this.props.clear(this.props.facet, item) })
  }

}
