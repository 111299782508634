// @ts-nocheck
import h from 'hyperscript';
import $ from 'jquery';
import Swiper from 'swiper';
// import 'swiper/dist/css/swiper.css';

import { isCompare, toggleCompare } from "../car-detail/car-compare";
import { isFavorite, toggleFavorite } from "../car-detail/car-favorites";
import RdLeesmeer from "../../01-components/rd-leesmeer/rd-leesmeer";

export default function carDetailInit(context: HTMLElement) {
  $(".contenttype--vehiclemediaitem.car-offer, .contenttype--vehiclemediaitem .car-offer", context).each((_, carOfferEl) => {
    const $carOfferEl = $(carOfferEl);
    const id = $carOfferEl.find("[data-identifier]").attr("data-identifier");
    if (!id) {
      console.warn("Can't find car ID for el", $carOfferEl);
      return;
    }

    // Bail out if we're already initialized
    if (carOfferEl.querySelector(".car-offer__buttons")) {
      return;
    }

    // Add button container
    const buttonContainer = h("div", { className: "car-offer__buttons" });
    carOfferEl.appendChild(buttonContainer);

    // Add favorite button

    const favoriteButtonClasses = isFavorite(id) ? "car-offer__save car-offer__save--active" : "car-offer__save";
    const favoriteButton = h("button", { className: favoriteButtonClasses }, "Bewaar");

    buttonContainer.appendChild(favoriteButton);

    $(favoriteButton).on("click", (event) => {
      event.preventDefault();
      const isFavorite = toggleFavorite(id);
      favoriteButton.classList.add("car-offer__save--active");
      isFavorite ? favoriteButton.classList.add("car-offer__save--active") : favoriteButton.classList.remove("car-offer__save--active");
    });

    // Add compare button

    const compareButtonClasses = isCompare(id) ? "car-offer__compare car-offer__compare--active" : "car-offer__compare";
    const compareButton = h("button", { className: compareButtonClasses }, "Vergelijk");

    buttonContainer.appendChild(compareButton);

    $(compareButton).on("click", (event) => {
      event.preventDefault();
      const isCompare = toggleCompare(id);
      isCompare ? compareButton.classList.add("car-offer__compare--active") : compareButton.classList.remove("car-offer__compare--active");
    });
  });

  $(".car-offer__save", context).each((_, favoriteButton) => {
    const id = $(favoriteButton).attr("data-id");
    if (!id) {
      return;
    }

    if (isFavorite(id)) {
      favoriteButton.classList.add("car-offer__save--active");
    }

    $(favoriteButton).on("click", (event) => {
      event.preventDefault();
      const isFavorite = toggleFavorite(id);
      isFavorite ? favoriteButton.classList.add("car-offer__save--active") : favoriteButton.classList.remove("car-offer__save--active");
    });
  });

  $(".block-crumb-path .green__arrowleft", context).each((_, backButton) => {
    $(backButton).on("click", (event) => {
      if (document.referrer.includes('/voorraad.htm')) {
        event.preventDefault();
        history.back();
      }
    })
  });

  $(".copylink", context).each((_, shareLink) => {
    $(shareLink).on("click", () => {
      const copySuccess = $(shareLink).next(".copy__success");
      copySuccess.css('opacity', '1');
      copySuccess.css('display', 'block');

      setTimeout(() => {
        copySuccess.css('opacity', '0');
        setTimeout(() => {
          copySuccess.css('display', 'none');
        }, 500);
      }, 2500);
    });
  });

  document.querySelectorAll('.car-offer').forEach(carousel => {
    const swiperContainer: HTMLElement | null = carousel.querySelector('.swiper-container');
    const nextEl: HTMLElement | null = carousel.querySelector('.swiper-button-next');
    const prevEl: HTMLElement | null = carousel.querySelector('.swiper-button-prev');

    if (!swiperContainer || !nextEl || !prevEl) {
      return;
    }
    // eslint-disable-next-line no-new
    new Swiper(swiperContainer, {
      // Disable preloading of all images
      preloadImages: false,
      // Enable lazy loading
      lazy: true,
      slidesPerView: 1,
      spaceBetween: 0,
      navigation: {
        nextEl,
        prevEl,
      },
      loop: false,
      longSwipesRatio: 0.25,
    });

    const mainContent: HTMLElement | null = document.querySelector('.page--aanbod');
    if (!mainContent) {
      return;
    }
  });

  // $('.showmore__wrapper-plp').on('click', ({ currentTarget }) => {
  //           console.log('00');
  //
  //   const $item = $(<HTMLElement> currentTarget.parentElement);
  //           console.log('a', $item);
  //
  //   $item.toggleClass('showmore__item-plp--active');
  //           console.log('b', $item);
  //
  //   // let changeText = $('.showmore__target-plp').text();
  //   // $('.showmore__target-plp').text(
  //   //   changeText == 'Lees minder' ? 'Lees meer' : 'Lees minder');
  // });

  RdLeesmeer();
}

const container = document.querySelector("body");
if (container) {
  carDetailInit(container);
}
