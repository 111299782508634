import $ from "jquery";

export default function RdLeesmeer() {
  $('.showmore__wrapper-plp').on('click', ({ currentTarget }) => {
    const $item = $(<HTMLElement> currentTarget.parentElement);
    $item.toggleClass('showmore__item-plp--active');
    let changeText = $('.showmore__target-plp').text();
    $('.showmore__target-plp').text(
      changeText == 'Lees minder' ? 'Lees meer' : 'Lees minder');
  });
}
