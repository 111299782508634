// @ts-nocheck

import { debounce } from 'debounce';
import React from 'react';
import { IRangeFacet } from './facet';

interface IRangeFacetProps {
  expanded : boolean;
  facet : IRangeFacet;
  onChange : (item : IRangeFacet, min : number | null, max : number | null) => void;
}

interface IFacetState {
  facetExpaned : boolean;
  max : number | null;
  min : number | null;
  maxPlaceHolder : string | null;
  minPlaceHolder : string | null;
}

export default class RangeFacet extends React.Component<IRangeFacetProps, IFacetState> {
  private debouncedCallOnChange : any;

  constructor(props : IRangeFacetProps) {
    super(props);

    this.debouncedCallOnChange = debounce(this.callOnChange, 500);

    this.onChangeMin = this.onChangeMin.bind(this);
    this.onChangeMax = this.onChangeMax.bind(this);
    this.toggleFacetExpanded = this.toggleFacetExpanded.bind(this);

    this.state = {
      facetExpaned: props.expanded,
      max: props.facet.max,
      min: props.facet.min,
      maxPlaceHolder : props.facet.maxPlaceHolder,
      minPlaceHolder : props.facet.minPlaceHolder,
    };
  }

  public render() : JSX.Element {
    return (
      <div className={`facet facet--range ${this.state.facetExpaned ? "facet--expanded" : "facet--collapsed"}`}>
        <div className="facet__title" onClick={this.toggleFacetExpanded}>{this.props.facet.title}</div>
        {this.renderBody()}
      </div>
    )
  }

  private renderBody() : JSX.Element | null {
    if (!this.state.facetExpaned) {
      return null;
    }

    return (
      <div className="facet__inputs">
        <input type="number" placeholder={this.state.minPlaceHolder === null ? "Van" : this.state.minPlaceHolder.toString()} value={this.state.min === null ? "" : this.state.min.toString()} onChange={this.onChangeMin} min="0"/>
        <input type="number" placeholder={this.state.maxPlaceHolder === null ? "Tot" : this.state.maxPlaceHolder.toString()}  value={this.state.max === null ? "" : this.state.max.toString()} onChange={this.onChangeMax} min="0"/>
      </div>
    );
  }

  private toggleFacetExpanded() : void {
    this.setState({facetExpaned: !this.state.facetExpaned});
  }

  private onChangeMin(event : React.FormEvent<HTMLInputElement>) : void {
    const val : number | null = event.currentTarget.value.length ? Number(event.currentTarget.value) : null;
    this.setState({min: val});
    this.debouncedCallOnChange();
  }

  private onChangeMax(event : React.FormEvent<HTMLInputElement>) : void {
    const val : number | null = event.currentTarget.value.length ? Number(event.currentTarget.value) : null;
    this.setState({max: val});
    this.debouncedCallOnChange();
  }

  private callOnChange() : void {
    this.props.onChange(this.props.facet, this.state.min, this.state.max);
  }
}
