import React from 'react';
import $ from 'jquery';

import {__DEV__} from "../constants";
import { removeCompare, getCompares } from "../car-detail/car-compare";
import Car, { ICar, ICarProperty } from '../-old/car-favorites/car';
import { IFavorite } from '../car-detail/car-favorites';
import CarCompareSegment from './car-compare-segment';

const ENDPOINT = __DEV__ ? "/main/api/vehicles.json" : "/autodata.htm";

const COL_WIDTH : number = 250;

interface ICarCompareState {
  cars : ICar[];
  loading : boolean;
  showDifferencesOnly : boolean;
}

export default class CarCompare extends React.Component<{}, ICarCompareState> {
  constructor(props : {}) {
    super(props);

    this.closeOverlay = this.closeOverlay.bind(this);
    this.removeCompare = this.removeCompare.bind(this);
    this.toggleDifferencesOnly = this.toggleDifferencesOnly.bind(this);

    this.state = {
      cars: [],
      loading: false,
      showDifferencesOnly : false,
    };
  }

  public componentDidMount() {
    this.fetchCars();
  }

  public render() : JSX.Element {
    if (this.state.loading) {
      return (
        <div>
          <div>Laden...</div>
        </div>
      );
    }

    if (this.state.cars.length === 0) {
      return (
        <div className="tr-segment__content">
          <h2><span>U heeft geen auto's geselecteerd om te vergelijken</span></h2>
        </div>
      );
    }

    return (
      <div role="main">
        <div className="car-compare" style={{width: (this.state.cars.length + 1) * COL_WIDTH}}>
          <div className="car-compare__header">
            <h2><span>Auto's vergelijken</span></h2>
            <a href="#" className="car-compare__close" onClick={this.closeOverlay}>Sluiten</a>
          </div>

          <div className="car-compare-row">
            <div className="car-compare-cell"></div>
            {this.state.cars.map((car : ICar) => {
              return (
                <div className="car-compare-cell">
                  <Car car={car} compact={true} onRemove={this.removeCompare} />
                </div>
              );
            })}
          </div>

          <div className="car-compare-differences-setting facet--filter">
            <input type="checkbox" id="only-differences" checked={this.state.showDifferencesOnly} onChange={this.toggleDifferencesOnly} />
            <label htmlFor="only-differences">Toon alleen de verschillen</label>
          </div>

          <CarCompareSegment cars={this.state.cars} category="Samenvatting" differencesOnly={this.state.showDifferencesOnly} />
          <CarCompareSegment cars={this.state.cars} category="Interieur" differencesOnly={this.state.showDifferencesOnly} />
          <CarCompareSegment cars={this.state.cars} category="Exterieur" differencesOnly={this.state.showDifferencesOnly} />
          <CarCompareSegment cars={this.state.cars} category="Veiligheid" differencesOnly={this.state.showDifferencesOnly} collaped={true} />
          <CarCompareSegment cars={this.state.cars} category="Infotainment" differencesOnly={this.state.showDifferencesOnly} collaped={true} />
          <CarCompareSegment cars={this.state.cars} category="Overige" differencesOnly={this.state.showDifferencesOnly} collaped={true} />
        </div>
      </div>
    );
  }

  private fetchCars() : void {
    const favorites : IFavorite[] = getCompares();

    $.getJSON(
      ENDPOINT,
      {
        identifier: favorites.map((favorite) => favorite.id),
      },
    )
    .done((response : any) => {
      const cars : ICar[] = response.vehicles.map((vehicle : any) => {
        const properties : ICarProperty[] = [];
        Object.keys(vehicle).filter((category) => typeof(vehicle[category]) === "object").map((category) => {
          Object.keys(vehicle[category]).map((k) => {
            properties.push({
              category,
              ...vehicle[category][k]
            })
          });
        });

        return {
          id: vehicle.voertuignrHexon,
          image : vehicle.image,
          title: vehicle.title,
          subtitle: vehicle.subtitle,
          fromPrice: vehicle.fromPrice,
          offerPrice: vehicle.offerPrice,
          discount: vehicle.discount,
          vestiging: vehicle.vestiging,
          properties : properties,
        };
      });

      this.setState({
        cars,
        loading: false,
      });
    })
    .fail((e, f) => {
      console.warn("Failed to fetch vehicles", e, f);
      this.setState({
        cars: [],
        loading: false,
      });
    });
  }

  private removeCompare(car : ICar) : void {
    removeCompare(car.id);

    this.setState({
      cars: this.state.cars.filter((c) => c.id !== car.id),
    })
  }

  private toggleDifferencesOnly() : void {
    this.setState({
      showDifferencesOnly: !this.state.showDifferencesOnly,
    })
  }

  private closeOverlay(e : any) : void {
    e.preventDefault();

    const container = document.querySelector("body");
    const overlay = container ? container.querySelector(".car-compare-overlay") : null;

    if (container && overlay) {
      container.classList.remove("car-compare-overlay-open");
      overlay.remove();
    }
  }
}
