import Swiper, { Navigation, Lazy } from 'swiper';

document.querySelectorAll('.header-carousel').forEach(carousel => {
  const swiperContainer: HTMLElement | null = carousel.querySelector('.swiper');
  const nextEl: HTMLElement | null = carousel.querySelector('.swiper-button-next');
  const prevEl: HTMLElement | null = carousel.querySelector('.swiper-button-prev');

  if (!swiperContainer || !nextEl || !prevEl) {
    return;
  }

  new Swiper(swiperContainer, {
    modules: [Navigation, Lazy],
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: true,
    loadPrevNext: true,
    loadPrevNextAmount: 2,
    // rewind: true,
    slidesPerView: 1,
    spaceBetween: 0,
    navigation: {
      nextEl,
      prevEl,
    },
    speed: 500,
    loop: true,
    longSwipesRatio: 0.25,
    autoplay: {
      delay: 5000,
    },
  });

  const mainContent: HTMLElement | null = document.querySelector('.page--model .content-header');

  if (!mainContent) {
    return;
  }

  let bottom: number = parseInt(window.getComputedStyle(carousel).height) - parseInt(window.getComputedStyle(mainContent).height) + 24;

  pagination.style.bottom = bottom + 'px';
});
