import Swiper, {Navigation, Pagination, Scrollbar, Controller, Keyboard} from "swiper";

const swiper_models = new Swiper(".modelsSwiper", {
    modules: [Navigation, Pagination, Scrollbar, Keyboard, Controller],
    keyboard: {
        enabled: true,
    },
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 60,
    rewind: true,
    noSwiping: true,
    noSwipingClass: 'swiper-no-swiping',
    scrollbar: {
        el: ".swiper-scrollbar",
        hide: false,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
    },
    breakpoints: {
        768: {
            slidesPerView: 1.5,
        },
        992: {
            slidesPerView: 2.5,
        },
    },
    on: {
        slideChange: function () {
            let currentSlide = this.realIndex + 1;
            let currentSlidePad = Number(currentSlide).toString().padStart(2, '0');
            document.querySelector('.modelsSwiperCounterStart').innerHTML = currentSlidePad;
        },
        beforeInit: function () {
            let numOfSlides = this.wrapperEl.querySelectorAll(".swiper-slide").length;
            let numOfSlidesPad = Number(numOfSlides).toString().padStart(2, '0');
            document.querySelector('.modelsSwiperCounterTotal').innerHTML = numOfSlidesPad;
        }
    }
});

const swiper_modelscontent = new Swiper(".modelsContentSwiper", {
    modules: [Navigation, Controller],
    slidesPerView: 1,
    slidesPerGroup: 1,
    spaceBetween: 60,
    cssMode: true,
    speed: 500,
    effect: 'fade',
});

swiper_models.controller.control = swiper_modelscontent;
swiper_modelscontent.controller.control = swiper_models;