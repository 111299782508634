import $ from 'jquery';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/autocomplete.js';

export default function SearchInHeader() {

  $(document).ready(autocomplete);

  $(document).ready(function() {
    // Hero box on home page is created later
    // Add autocomplete again when hero box is added
    const searchBoxHome: Node = < Node > document.getElementById('facetinfo');
    if (searchBoxHome) {
      const config = {
        childList: true,
        subtree: true
      };
      const observer = new MutationObserver(autocomplete);
      observer.observe(searchBoxHome, config);
    }
  });


  function autocomplete() {
    var searchInfoDivs: JQuery = $("div.fssearchinfo");

    if (searchInfoDivs !== undefined && searchInfoDivs.length != 0) {
      searchInfoDivs.each(function(i, val) {
        var fsInitAutoCompletion = $(val).data("fsinitautocompletion");
        var fieldId: string = 'query' + fsInitAutoCompletion;
        var queryFieldId: any = $('#' + fieldId);

        if ($(val).data("useautocompletion")) {
          autocompleteFields($(val), queryFieldId);
        }
      });
    }
  }

  function autocompleteFields(searchInfoDiv: JQuery, queryFieldId: any) {
    if (queryFieldId && queryFieldId.length != 0) {

      // https://api.jqueryui.com/autocomplete/
      queryFieldId.autocomplete({

          // function that returns an array of JSON objects, each with a "label" key and a "value" key
          source: function(request: any, response: any) {
            getJSON(request, response, searchInfoDiv);
          },

          // when to start autocompleting
          minLength: searchInfoDiv.data("fsautocompletechars"),

          // what to do when one of the suggestions is clicked
          select: function(event: Event, ui: any) {
            event.preventDefault();

            if (!ui.item.label.includes("title")) {
              if (ui.item.label.includes("vehicle-autocomplete")) {
                // Redirect to aanbod page
                var redirectUrl = $("div.fssearchinfo").data("voorraadurl");
                window.location.href = redirectUrl + "?query=" + ui.item.value;
              } else {
                $(this).val(ui.item.value);
                $(this).closest("form").submit();
              }
            }
          }
        })
        // make custom changes to the li items holding the suggestions
        .data("ui-autocomplete")._renderItem = function(ul: JQuery, item: any) {
          const className: string = item.label.includes("vehicle-autocomplete") || item.label.includes("title") ? item.label : "sitewide-autocomplete";
          return $("<li>")
            .addClass(className)
            .attr("data-value", item.value)
            .append(item.value)
            .appendTo(ul);
        };

      queryFieldId.autocomplete("option", "appendTo", searchInfoDiv);
    }
  }

  function getJSON(request: any, response: any, searchInfoDiv: JQuery) {
    var json: Object[] = [];
    var json1: Object[] = [];
    var json2: Object[] = [];
    var autoCompleteVehicleUrl = searchInfoDiv.data("vehicleautocompleteurl");
    var autoCompleteFsUrl = searchInfoDiv.data("fsautocompleteurl");

    var autoCompleteVehicleResult, autoCompleteFsResult;


    if (!searchInfoDiv.parents('.google-links').length && autoCompleteFsUrl !== undefined && autoCompleteFsUrl.length > 0) {
      autoCompleteFsResult = $.getJSON(autoCompleteFsUrl, {
        q: request.term
      }, function(data1) {
        if (data1.length > 0) {
          data1 = data1.slice(0, 5);
          data1.unshift({
            "label": "sitewide-autocomplete-title",
            "value": "Zoeken in de gehele site"
          });
          json1 = data1;
        }
      });
    };
    if (autoCompleteVehicleUrl !== undefined && autoCompleteVehicleUrl.length > 0) {
      autoCompleteVehicleResult = $.getJSON(autoCompleteVehicleUrl, {
        q: request.term
      }, function(data2) {
        if (data2.length > 0) {
          data2 = data2.slice(0, 5);
          data2.unshift({
            "label": "vehicle-autocomplete-title",
            "value": "Zoeken in ons aanbod"
          });
          json2 = data2;
        }
      });
    };
    $.when(autoCompleteFsResult, autoCompleteVehicleResult).done(function() {
      if (json1.length > 0 && json2.length > 0) {
        json = json1.concat(json2);
        response(json);
      } else if (json1.length > 0) {
        response(json1);
      } else if (json2.length > 0) {
        response(json2);
      }
    });

  }
}
