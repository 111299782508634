import Swiper, { Navigation, Scrollbar } from "swiper";

const swiper = new Swiper(".articlesSwiper", {
  modules: [Navigation, Scrollbar],
  slidesPerView: 1.5,
  spaceBetween: 30,
  scrollbar: {
    el: ".swiper-scrollbar",
    hide: false,
  },
  navigation: {
    nextEl: ".swiper-button-next",
    prevEl: ".swiper-button-prev",
  },
  breakpoints: {
    800: {
      slidesPerView: 2.5,
    },
    1200: {
      slidesPerView: 3.5,
    },
  }
});
