import React from 'react';
import { ICar, carProp, commonCarProps, ICarProperty } from '../-old/car-favorites/car';

interface ICarCompareSegmentProps {
  cars : ICar[];
  category : string;
  collaped : boolean;
  differencesOnly : boolean;
}

interface ICarCompareSegmentState {
  collaped : boolean;
}

export default class CarCompareSegment extends React.Component<ICarCompareSegmentProps, ICarCompareSegmentState> {
  static defaultProps = {
    collaped: false,
    differencesOnly : false,
  }

  constructor(props : ICarCompareSegmentProps) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = {
      collaped: props.collaped,
    };
  }

  public render() : JSX.Element | null {
    const commonProps : ICarProperty[] = commonCarProps(this.props.cars, this.props.category, {differencesOnly: this.props.differencesOnly});
    if (!commonProps.length) {
      return null;
    }

    return (
      <div className={`car-compare-segment ${this.state.collaped ? "car-compare-segment--collapsed" : ""}`}>
        <h3 className="car-compare-segment__title" onClick={this.toggle}>{this.props.category}</h3>
        {this.state.collaped? null : <div className="car-compare-table">
          {commonProps.map((property : ICarProperty) => {
            return (
              <div className="car-compare-row">
                <div className="car-compare-cell car-compare-cel--label">
                  {property.label}
                </div>
                {this.props.cars.map((car : ICar) => this.renderPropValue(car, property))}
              </div>
            );
          })}
        </div>}
      </div>
    );
  }

  private renderPropValue(car : ICar, property : ICarProperty) : JSX.Element {
    const propValue : string | boolean | null = carProp(car, property.name);

    if (typeof(propValue) === "boolean") {
      return (
        <div className="car-compare-cell">
          {propValue === true ? <span className="checkmark" /> : "-"}
        </div>
      );
    }

    return (
      <div className="car-compare-cell">
        {propValue}
      </div>
    );
  }

  private toggle() : void {
    this.setState({
      collaped: !this.state.collaped,
    });
  }

}
