import $ from 'jquery';

export default function HeaderSticky() {
  const desktop = 1150;
  let mobileMode = false;

  // Switch between mobile and desktop logic
  if (window.innerWidth < desktop) {
      mobileMode = true;
  }

  let stickyHeader: JQuery = $('.sticky-header');
  $('.sticky-header').hide();

  // Don't hide the pagepart in the edit environment
  if (stickyHeader.attr('data-layout-id')) {
      $('.sticky-header').show();
  } else if (!mobileMode) {
      $(window).scroll( function(){
          let scrollThreshold: JQuery = $('.column-1, .primary__info');
          if (scrollThreshold) {
              let offset: any = scrollThreshold.offset();
              if (offset) {
                  let bottom_of_object: number = offset.top + scrollThreshold.outerHeight();
                  let top_of_window: number | undefined = $(window).scrollTop();

                  // If the buttons are not visible anymore, show the header instead
                  if( top_of_window && bottom_of_object && top_of_window > bottom_of_object ){
                      $('.sticky-header').show();
                  } else if( top_of_window && bottom_of_object && top_of_window <= bottom_of_object ){
                      $('.sticky-header').hide();
                  }
              }
          }
      });
  }
}
