import Swiper, { Navigation, Lazy } from 'swiper';

export default function sliderSrcPlpRd() {
  document.querySelectorAll('.card-car-slider').forEach(carousel => {
    const swiperContainer: HTMLElement | null = carousel.querySelector('.swiper');
    const nextEl: HTMLElement | null = carousel.querySelector('.swiper-button-next');
    const prevEl: HTMLElement | null = carousel.querySelector('.swiper-button-prev');

    if (!swiperContainer || !nextEl || !prevEl) {
      return;
    }

    new Swiper(swiperContainer, {
      modules: [Navigation, Lazy],
      slidesPerView: 1,
      // Disable preloading of all images
      preloadImages: true,
      // Enable lazy loading
      lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 2,
      },
      navigation: {
        nextEl,
        prevEl,
      },
    });
  });
}
