import { __DEV__ } from "../constants";
import $ from 'jquery';

const FAVORITE_KEY = "carFavorites";
const ENDPOINT = __DEV__ ? "/" : "https://oostendorp.blueconic.net/rest/custom/frontend/favorites_rest_endpoint/favorites";

function getCookie(name : string) {
	var value = "; " + document.cookie;
	var parts = value.split("; " + name + "=");
	if (parts.length == 2) return parts.pop()!.split(";").shift();
};
const BLUECONIC_PROFILE_ID = getCookie('BCSessionID') || "BLUECONIC_PROFILE_ID";

export interface IFavorite {
  id : string;
}

let favorites : IFavorite[] = []

export function loadFavorites() {
  const rawFavorites = localStorage.getItem(FAVORITE_KEY);
  favorites = rawFavorites ? JSON.parse(rawFavorites) : [];
}

function storeFavorites() {
  localStorage.setItem(FAVORITE_KEY, JSON.stringify(favorites));
}

export function getFavorites() : IFavorite[] {
  return favorites;
}

export function isFavorite(id : string) {
  return favorites.findIndex((favorite) => favorite.id === id) !== -1;
}

export function toggleFavorite(id : string) {
  isFavorite(id) ? removeFavorite(id) : addFavorite(id);
  return isFavorite(id);
}

function addFavorite(id : string) {
  favorites.push({id});
  storeFavorites();

  $.ajax({
    url: `${ENDPOINT}?profileId=${BLUECONIC_PROFILE_ID}&favoriteId=${id}`,
    type: 'PUT',
  }).fail(() => {
    console.error("Error adding favorite");
  });
}

export function removeFavorite(id : string) {
  favorites = favorites.filter((favorite) => favorite.id !== id);
  storeFavorites();

  $.ajax({
    url: `${ENDPOINT}?profileId=${BLUECONIC_PROFILE_ID}&favoriteId=${id}`,
    type: 'DELETE',
  }).fail(() => {
    console.error("Error deleting favorite");
  });
}

loadFavorites();
