import Swiper, {Lazy} from 'swiper';

let swiper = Swiper;
let init = false;

function swiperMode() {
    let mobile = window.matchMedia('(min-width: 0px) and (max-width: 768px)');
    let desktop = window.matchMedia('(min-width: 769px');

    if (mobile.matches) {
        if (!init) {
            init = true;


            const swiper = new Swiper(".swiperHomepage", {
                modules: [Lazy],
                slidesPerView: 1.5,
                spaceBetween: 30,
                speed: 400,
                initialSlide: 0,
                direction: 'horizontal',
                loop: true,
                autoplay: 5000,
                autoplayStopOnLast: false,
                effect: 'slide',
                slidesOffsetBefore: 0,
                grabCursor: true,
                breakpoints: {
                    768: {
                        spaceBetween: 0,
                        slidesPerView: 3,
                        allowSlideNext: false,
                        allowSlidePrev: false,
                        allowTouchMove: false,
                        grabCursor: false,
                        loop: false,
                    },
                },
            });
        }
    } else if (desktop.matches) {
        console.log('destroy');
        // swiper.disable()
        init = false;
    }
}

window.addEventListener('load', function () {
    swiperMode();
});

window.addEventListener('resize', function () {
    swiperMode();
});

// 4 juni 11.25u 

const activateSlide = document.querySelectorAll('.swiper-slide');

if (!activateSlide) {
    activateSlide.forEach(function (el, key) {
        el.addEventListener('mouseover', function () {
            el.classList.toggle("activeSlide");
            activateSlide.forEach(function (ell, els) {
                if (key !== els) {
                    ell.classList.remove('activeSlide');
                }
            });
        });
    });
}