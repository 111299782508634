import React from 'react';
import { ISelectFacet, IFacetItem } from './facet';

const SHOW_ITEMS_WHEN_COLLAPSED = 5;

interface IFacetProps {
  expanded : boolean;
  facet : ISelectFacet;
  onToggle : (item : IFacetItem) => void;
  showExpander : boolean;
}

interface IFacetState {
  facetExpaned : boolean;
  itemsExpanded : boolean;
}

export default class SelectFacet extends React.Component<IFacetProps, IFacetState> {
  static defaultProps = {
    showExpander : false,
  }

  constructor(props : IFacetProps) {
    super(props);

    this.toggleFacetExpanded = this.toggleFacetExpanded.bind(this);
    this.toggleItemsExpanded = this.toggleItemsExpanded.bind(this);
    this.toggleSelected = this.toggleSelected.bind(this);

    // console.warn("SelectFacet constructor");
    this.state = {
      facetExpaned: props.expanded,
      itemsExpanded: !props.showExpander,
    };
  }
  public render() : JSX.Element {
    return (
      <div className={`facet facet--filter facet--select ${this.state.facetExpaned ? "facet--expanded" : "facet--collapsed"}`}>
        <div className="facet__title" onClick={this.toggleFacetExpanded}>{this.props.facet.title}</div>
        {this.renderItems()}
        {this.renderItemsExpandButton()}
      </div>
    )
  }

  private toggleFacetExpanded() : void {
    this.setState({facetExpaned: !this.state.facetExpaned});
  }

  private toggleItemsExpanded(event : any) : void {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    this.setState({itemsExpanded: !this.state.itemsExpanded});
  }

  private toggleSelected(item : IFacetItem) : void {
    this.props.onToggle(item);
  }

  private renderItems() : JSX.Element | null {
    if (!this.state.facetExpaned) {
      return null;
    }

    const showItems: number = this.state.itemsExpanded ? Infinity : SHOW_ITEMS_WHEN_COLLAPSED;

    return (
      <ol className="facet__items">
        {this.props.facet.items.slice(0, showItems).map((item : IFacetItem) => this.renderItem(item))}
      </ol>
    );
  }

  private renderItem(item : IFacetItem) : JSX.Element {
    const inputName : string = `facet-${this.props.facet.key}-${item.key}`;
	let itemHasResults : boolean = typeof(item.count) === "number" && item.count > 0;
    return (
      <li className="facet__item">
        <input type="radio" id={inputName} name={this.props.facet.key} value={item.key} checked={item.selected} onChange={this.toggleSelected.bind(this, item)}  disabled={!itemHasResults} />
        <label htmlFor={inputName} className={typeof(item.count) === "number" && item.count > 0 ? "" : "greyed_out"} >
          {this.decodeHTML(item.title)}
        </label>
      </li>
    );
  }
  
  private decodeHTML(title : string) : string {
	let euro: HTMLTextAreaElement = document.createElement('textarea');
	euro.innerHTML = title;
	return euro.value;
  };

  private renderItemsExpandButton() : JSX.Element | null {
    if (!this.props.showExpander) {
      return null;
    }
    if (!this.state.facetExpaned) {
      return null;
    }
    if (this.props.facet.items.length <= SHOW_ITEMS_WHEN_COLLAPSED) {
      return null;
    }

    if (this.state.itemsExpanded) {
      return (
        <a href="#" className="facet__items-expander facet__items-expander--expanded" onClick={this.toggleItemsExpanded}>Toon minder</a>
      );
    } else {
      return (
        <a href="#" className="facet__items-expander facet__items-expander--collapsed" onClick={this.toggleItemsExpanded}>Toon meer</a>
      );
    }
  }
}
