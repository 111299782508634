import Swiper, { Navigation, Lazy } from 'swiper';
import $ from 'jquery';
import h from 'hyperscript';

if (document.querySelector('.page--car-detail')) {
  const $slides = $('.primary__main .swiper-slide');
  $slides.each((index, element) => {
    element.appendChild(
      h('p.swiper-slide__counter', `${index + 1}/${$slides.length}`),
    );
  });
}
new Swiper('.primary__main .swiper-container', {
  modules: [Navigation, Lazy],
  // Disable preloading of all images
  preloadImages: false,
  // Enable lazy loading
  lazy: {
     loadPrevNext: true,
     loadPrevNextAmount: 2,
   },
  // Navigation arrows
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },
});
