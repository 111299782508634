import React from 'react';
import $ from "jquery";

import initializeCarDetail from '../car-detail/car-detail';
import initializeSort from '../../01-components/-old/sort-options/sort-options';

import {IFacetItem, Facet, ISearchFacet, IRangeFacet, IFilterFacet, ISelectFacet} from './facet';
import SelectedFacets from './selected-facets';
import FilterFacet from './filter-facet';
import SearchFacet from './search-facet';
import RangeFacet from './range-facet';
import SelectFacet from './select-facet';

// How many facets should be initially visible
const SHOW_FACETS_COUNT = 10;

// How many facets should be initially expanded
const EXPANDED_FACETS_COUNT = 6;

interface IVehicleSearchProps {
    facetsEndpoint: string | null;
    carsEndpoint: string | null;
    carsUrlBase: string;
    carsHTML: string;
}

interface IVehicleSearchState {
    carsHTML: string;
    updateTitleScript: string;
    /* Keep track of times a filter is cleared so we can render new components (by using a new key),
       so local state of the search and range filters are reset. */
    clearCount: number;
    expanded: boolean;
    showFilters: boolean;
    mobileMode: boolean;
    facets: Facet[];
    firstRender: boolean;
    loadingCars: boolean;
    loadingFacets: boolean;
    orderby: string;
    sortorder: string;
    from: number;
    isVacatureSearch: boolean;
    isVehicleSearch: boolean;
}

export default class VehicleSearch extends React.Component<IVehicleSearchProps, IVehicleSearchState> {
    private carsRef: React.RefObject<HTMLDivElement>;

    constructor(props: IVehicleSearchProps) {
        super(props);

        this.changeRangeFacet = this.changeRangeFacet.bind(this);
        this.changeSearchFacet = this.changeSearchFacet.bind(this);
        this.clear = this.clear.bind(this);
        this.clearAll = this.clearAll.bind(this);
        this.toggleShowFilters = this.toggleShowFilters.bind(this);
        this.toggleExpanded = this.toggleExpanded.bind(this);
        this.toggleFacetItem = this.toggleFacetItem.bind(this);

        this.carsRef = React.createRef();

        this.state = {
            carsHTML: props.carsHTML,
            updateTitleScript: "",
            clearCount: 0,
            expanded: false,
            showFilters: true,
            mobileMode: false,
            facets: [],
            firstRender: true,
            loadingCars: false,
            loadingFacets: true,
            orderby: "",
            sortorder: "",
            from: 0,
            isVacatureSearch: false,
            isVehicleSearch: true
        };
    }

    public componentDidMount(): void {
        //switch between mobile and desktop logic
        if (window.innerWidth < 768) {
            this.setState({
                showFilters: false,
                mobileMode: true,
            });
        }

        this.fetchFacets();
    }

    public componentDidUpdate(): void {
        if (this.carsRef.current) {
            initializeCarDetail(this.carsRef.current);
            window.eval(this.state.updateTitleScript);
            this.moveCategoryText();
            if (!this.state.firstRender) {
                initializeSort();
            }
        }
    }

    public render(): JSX.Element {
        return (
            <div className="vehicle-search">
                {this.state.mobileMode ? this.renderFacetsMobile() : this.renderFacetsDesktop()}
                {this.renderVehicles()}
            </div>
        )
    }

    private renderLoader(): JSX.Element {
        return (
            <div className="spinner">Laden...</div>
        );
    }

    private renderFacetsMobile(): JSX.Element {
        if (this.state.showFilters) {
            return (
                <div id="column-6" className="facets column column-side column-6">
                    <div className="third">
                        {this.renderExpandButton()}
                        <SelectedFacets clearAll={this.clearAll} clear={this.clear} facets={this.state.facets}/>
                        {this.state.facets.slice(0, Infinity).map((facet, facetIndex) =>
                            this.renderFacet(facet, facetIndex)
                        )}

                    </div>
                </div>
            );
        } else {
            return (
                <div id="column-6" className="facets column column-side column-6">
                    <div className="third">
                        {this.renderExpandButton()}
                    </div>
                </div>
            );
        }
    }

    private renderFacetsDesktop(): JSX.Element {
        const showFacets = this.state.expanded ? Infinity : SHOW_FACETS_COUNT;


        return (
            <div id="column-6" className="facets column column-side column-6">
                <div className="third">
                    <SelectedFacets clearAll={this.clearAll} clear={this.clear} facets={this.state.facets}/>
                    {this.state.facets.slice(0, showFacets).map((facet, facetIndex) =>
                        this.renderFacet(facet, facetIndex)
                    )}
                    {this.renderExpandButton()}
                </div>
            </div>
        );
    }

    private renderFacet(facet: Facet, facetIndex: number): JSX.Element {
        switch (facet.type) {
            case "filter":
                if (facet.key === "vestiging") {
                    return <FilterFacet key={`facet-${facet.key}-${this.state.clearCount}`} facet={facet}
                                        showExpander={false} expanded={facetIndex < EXPANDED_FACETS_COUNT}
                                        onToggle={this.toggleFacetItem} clear={this.clear}/>;
                } else {
                    return <FilterFacet key={`facet-${facet.key}-${this.state.clearCount}`} facet={facet}
                                        showExpander={true} expanded={facetIndex < EXPANDED_FACETS_COUNT}
                                        onToggle={this.toggleFacetItem} clear={this.clear}/>;
                }
            case "range":
                return <RangeFacet key={`facet-${facet.key}-${this.state.clearCount}`} facet={facet}
                                   expanded={facetIndex < EXPANDED_FACETS_COUNT} onChange={this.changeRangeFacet}/>;
            case "search":
                return <SearchFacet key={`facet-${facet.key}-${this.state.clearCount}`} facet={facet}
                                    expanded={facetIndex < EXPANDED_FACETS_COUNT} onChange={this.changeSearchFacet}/>;
            case "select":
                return <SelectFacet key={`facet-${facet.key}-${this.state.clearCount}`} facet={facet}
                                    showExpander={true} expanded={facetIndex < EXPANDED_FACETS_COUNT}
                                    onToggle={this.toggleFacetItem}/>;
        }
    }

    private renderExpandButton(): JSX.Element | null {
        if (this.state.mobileMode) {
            return this.renderExpandButtonMobile();
        } else if (this.state.facets.length <= SHOW_FACETS_COUNT) {
            return null;
        } else {
            return this.renderExpandButtonDesktop();
        }
    }

    private renderExpandButtonMobile(): JSX.Element | null {
        if (this.state.showFilters) {
            return (
                <button className="facet-expander-mobile hide" onClick={this.toggleShowFilters}>Verberg filters</button>
            );
        } else {
            return (
                <button className="facet-expander-mobile expand" onClick={this.toggleShowFilters}>Toon filters</button>
            );
        }
    }

    private renderExpandButtonDesktop(): JSX.Element | null {
        if (this.state.expanded) {
            return (
                <a href="#" className="facet-expander facet__items-expander facet__items-expander--expanded"
                   onClick={this.toggleExpanded}>Minder filters</a>
            );
        } else {
            return (
                <a href="#" className="facet-expander facet__items-expander facet__items-expander--collapsed"
                   onClick={this.toggleExpanded}>Alle filters</a>
            );
        }
    }

    private renderVehicles(): JSX.Element {
        if (this.state.loadingCars) {
            return (
                <div id="column-6" className="vehicle-search column column-side column-6">
                    <div className="third">
                        {this.renderLoader()}
                    </div>
                </div>
            );
        }

        return (
            <div id="column-6" className="vehicle-search column column-side column-6">
                <div ref={this.carsRef} className="third" dangerouslySetInnerHTML={{__html: this.state.carsHTML}}/>
            </div>
        );
    }

    private apiParams(state: IVehicleSearchState): {} {
        let params: any = {};

        state.facets.forEach((facet: Facet) => {
            switch (facet.type) {
                case "filter":
                    if (facet.items.some((item: IFacetItem) => item.selected)) {
                        params[facet.key] = facet.items.filter((item: IFacetItem) => item.selected).map((item: IFacetItem) => item.key);
                    }
                    break;
                case "range":
                    if (facet.min !== null) {
                        params[`${facet.key}from`] = facet.min;
                    }
                    if (facet.max !== null) {
                        params[`${facet.key}to`] = facet.max;
                    }
                    break;
                case "search":
                    if (facet.q.length > 0) {
                        params[facet.key] = facet.q;
                    }
                    break;
            }
        });

        if (state.orderby != "") {
            params["orderby"] = state.orderby;
        }
        if (state.sortorder != "") {
            params["sortorder"] = state.sortorder;
        }
        if (state.from != 0) {
            params["from"] = state.from;
        }
        return params;
    }

    private fetch() {
        this.fetchFacets();
        this.fetchCars();
    }

    private fetchFacets() {
        this.setState({
            loadingFacets: true,
        });

        const params = this.state.firstRender ? window.location.search.replace(/^\?/, "") : $.param(this.apiParams(this.state), true);

        $.getJSON(
            this.props.facetsEndpoint || "/",
            params,
        )
            .done((response: any, x, y) => {
                this.setState({
                    orderby: response.orderby,
                    sortorder: response.sortorder,
                    from: response.from,
                    isVehicleSearch: response.isVehicleSearch,
                    isVacatureSearch: response.isVacatureSearch,
                });

                let facets: Facet[] = response.facets.map((facet: any) => {
                    return {
                        type: "filter",
                        key: facet.technicalFacetName,
                        title: facet.visibleFacetName,
                        items: facet["facet-item"]["facetValues"].map((facetValue: any) => {
                            return {
                                key: facetValue.technicalName,
                                title: facetValue.visibleName,
                                count: facetValue.count,
                                selected: false
                            };
                        })
                    };
                });

                let titleSearch = this.state.isVehicleSearch ? 'Zoek auto' : 'Zoek';

                const searchFacet: Facet = {
                    type: "search",
                    key: "query",
                    title: titleSearch,
                    q: ""
                }
                facets.unshift(searchFacet);

                if (this.state.isVehicleSearch) {

                    const priceFacet: Facet = {
                        type: "range",
                        max: null,
                        min: null,
                        maxPlaceHolder: null,
                        minPlaceHolder: null,
                        key: "price",
                        title: "Prijs",
                    }
                    const kilometerFacet: Facet = {
                        type: "range",
                        max: null,
                        min: null,
                        maxPlaceHolder: null,
                        minPlaceHolder: null,
                        key: "kilometer",
                        title: "Kilometerstand",
                    }
                    const verbruikFacet: Facet = {
                        type: "range",
                        max: null,
                        min: null,
                        maxPlaceHolder: null,
                        minPlaceHolder: null,
                        key: "gemiddeldverbruik",
                        title: "Brandstofverbruik (l/100km)",
                    }
                    const trekgewichtFacet: Facet = {
                        type: "range",
                        max: null,
                        min: null,
                        maxPlaceHolder: "3.500 kg",
                        minPlaceHolder: "250 kg",
                        key: "maxtrekgewicht",
                        title: "Trekgewicht",
                    }

                    const bouwjaarFacetIndex: number = facets.findIndex((facet: Facet) => facet.key === "bouwjaar");
                    if (bouwjaarFacetIndex) {
                        facets = facets.slice(0, bouwjaarFacetIndex + 1).concat([priceFacet, kilometerFacet]).concat(facets.slice(bouwjaarFacetIndex + 1));
                    } else {
                        facets.unshift(priceFacet);
                        facets.unshift(kilometerFacet);
                    }
                    const basiskleurFacetIndex: number = facets.findIndex((facet: Facet) => facet.key === "basiskleur");
                    if (basiskleurFacetIndex) {
                        facets = facets.slice(0, basiskleurFacetIndex + 1).concat([verbruikFacet, trekgewichtFacet]).concat(facets.slice(basiskleurFacetIndex + 1));
                    } else {
                        facets.unshift(verbruikFacet);
                        facets.unshift(trekgewichtFacet);
                    }
                }

                this.setState({
                    facets: this.mergeFacetsValues(facets),
                    firstRender: false,
                    loadingFacets: false,
                });
            })
            .fail((e, f) => {
                //console.warn("Failed to facets", e, f);
                this.setState({
                    facets: [],
                    loadingFacets: false,
                });
            });
    }

    private mergeFacetsValues(facets: Facet[]): Facet[] {
        const mergedFacets: Facet[] = [];

        // Prefill / select facets from URL
        if (this.state.firstRender) {
            const parts = window.location.search.replace(/^\?/, "").split("&");
            parts.map((part) => {
                let [facet_key, item_key] = part.split("=");
                const isRangeMinFilter = Boolean(facet_key.match(/from$/));
                const isRangeMaxFilter = Boolean(facet_key.match(/to$/));
                facet_key = facet_key.replace(/from$/, "").replace(/to$/, "").replace("facet_", "");
                let item_key_lowercase = item_key ? item_key.toLowerCase() : item_key;

                const facet: Facet | undefined = facets.find((c: Facet) => c.key === facet_key);
                if (facet) {
                    switch (facet.type) {
                        case "filter":
                            const item: IFacetItem | undefined = (facet as IFilterFacet).items.find((i: IFacetItem) => i.key.toLowerCase() === unescape(item_key_lowercase));
                            if (item) {
                                item.selected = true;
                            }
                            break;
                        case "select":
                            const selectitem: IFacetItem | undefined = (facet as ISelectFacet).items.find((i: IFacetItem) => i.key.toLowerCase() === unescape(item_key_lowercase));
                            if (selectitem) {
                                selectitem.selected = true;
                            }
                            break;
                        case "range":
                            if (isRangeMinFilter) {
                                facet.min = Number(item_key);
                            }
                            if (isRangeMaxFilter) {
                                facet.max = Number(item_key);
                            }
                            break;
                        case "search":
                            facet.q = unescape(item_key);
                            break;
                    }
                }
            });
        }

        facets.forEach((facet: Facet) => {
            const currentFacet: Facet | undefined = this.state.facets.find((c: Facet) => c.key === facet.key);
            const newFacet: Facet = {...facet};

            if (currentFacet) {
                switch (newFacet.type) {
                    case "filter":
                        newFacet.items.forEach((item: IFacetItem) => {
                            const currentItem: IFacetItem | undefined = (currentFacet as IFilterFacet).items.find((i: IFacetItem) => i.key.toLowerCase() === item.key.toLowerCase());
                            if (currentItem) {
                                item.selected = currentItem.selected;
                            }
                        });
                        break;
                    case "range":
                        newFacet.max = (currentFacet as IRangeFacet).max;
                        newFacet.min = (currentFacet as IRangeFacet).min;
                        break;
                    case "search":
                        newFacet.q = (currentFacet as ISearchFacet).q;
                        break;
                }
            }

            mergedFacets.push(newFacet);
        });

        return mergedFacets;
    }

    private fetchCars(): void {
        this.setState({
            loadingCars: true,
        });

        const params = this.state.firstRender ? window.location.search.replace(/^\?/, "") : $.param(this.apiParams(this.state), true);

        let carsUrlBase: string = this.props.carsUrlBase;

        $.get(
            this.props.carsEndpoint || "/",
            params,
        )
            .done((response: any) => {
                const carsHTML = $("<div>").append($.parseHTML(response)).html();
                var extractedScript = /<script>(.+)<\/script>/gis.exec(response);
                this.setState({
                    carsHTML,
                    updateTitleScript: extractedScript ? extractedScript[1] : "",
                    loadingCars: false,
                });
            })
            .fail((e, f) => {
                console.warn("Failed to fetch cars", e, f);
                this.setState({
                    carsHTML: "",
                    updateTitleScript: "",
                    loadingCars: false,
                });
            })
            .always(function (this: any) {
                // Update the current URL to reflect selected facets, so a refresh of this page, or
                // bookmarking it will just work fine.
                let url: string = carsUrlBase;
                if (params) {
                    url += '?' + params;
                }
                history.pushState({}, document.title, url);
            });
    }

    private toggleShowFilters(event: any): void {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        this.setState({showFilters: !this.state.showFilters}, this.fetch);
    }

    private toggleExpanded(event: any): void {
        if (event && event.preventDefault) {
            event.preventDefault();
        }

        this.setState({expanded: !this.state.expanded}, this.fetch);
    }

    private toggleFacetItem(item: IFacetItem): void {
        item.selected = !item.selected;
        this.setState({facets: this.state.facets}, this.fetch);
    }

    private changeRangeFacet(item: IRangeFacet, min: number | null, max: number | null): void {
        item.min = min;
        item.max = max;
        this.setState({facets: this.state.facets}, this.fetch);
    }

    private changeSearchFacet(item: ISearchFacet, q: string): void {
        item.q = q;
        this.setState({facets: this.state.facets}, this.fetch);
    }

    private clearAll(): void {
        this.state.facets.forEach((facet) => {
            switch (facet.type) {
                case "filter":
                    facet.items.forEach(item => {
                        item.selected = false;
                    });
                    break;
                case "search":
                    facet.q = "";
                    break;
                case "range":
                    facet.max = null;
                    facet.min = null;
                    break;
            }
        });
        this.setState({clearCount: this.state.clearCount + 1, facets: this.state.facets}, this.fetch);
    }

    private clear(facet: Facet, item?: IFacetItem): void {
        switch (facet.type) {
            case "filter":
                item!.selected = false;
                break;
            case "range":
                facet.max = null;
                facet.min = null;
                break;
            case "search":
                facet.q = "";
                break;
        }
        this.setState({clearCount: this.state.clearCount + 1, facets: this.state.facets}, this.fetch);
    }

    private moveCategoryText(): void {
        let win: any;
        win = $(window);
        if (win.width() < 767) {
            $('.categoryText').insertBefore('.facets.column.column-side.column-6');
            $('#column-6 .categoryText').hide();
            if ($('.categoryText').length > 0) {
                $('.categoryText:nth-child(n+2)').remove();
            }
        }
    }
}
