import React from 'react';
import { Facet, IFilterFacet, IFacetItem } from './facet';

const SHOW_ITEMS_WHEN_COLLAPSED = 5;

interface IFacetProps {
  expanded : boolean;
  facet : IFilterFacet;
  onToggle : (item : IFacetItem) => void;
  showExpander : boolean;
  clear : (facet : Facet, item? : IFacetItem) => void;
}

interface IFacetState {
  facetExpanded : boolean;
  itemsExpanded : boolean;
}

export default class FilterFacet extends React.Component<IFacetProps, IFacetState> {
  static defaultProps = {
    showExpander : false,
	clear: {},
  }

  constructor(props : IFacetProps) {
    super(props);

    this.toggleFacetExpanded = this.toggleFacetExpanded.bind(this);
    this.toggleItemsExpanded = this.toggleItemsExpanded.bind(this);
    this.toggleSelected = this.toggleSelected.bind(this);
	this.clearFacet = this.clearFacet.bind(this);

    this.state = {
      facetExpanded: props.expanded,
      itemsExpanded: !props.showExpander,
    };
  }

  public render() : JSX.Element {
    return (
      <div className={`facet facet--filter ${this.state.facetExpanded ? "facet--expanded" : "facet--collapsed"}`}>
        <div className="facet__title" onClick={this.toggleFacetExpanded}>{this.props.facet.title}</div>
        {this.renderDeleteSelectionButton()}
		{this.renderItems()}
		{this.renderItemsExpandButton()}
      </div>
    )
  }

  private toggleFacetExpanded() : void {
    this.setState({facetExpanded: !this.state.facetExpanded});
  }

  private toggleItemsExpanded(event : any) : void {
    if (event && event.preventDefault) {
      event.preventDefault();
    }

    this.setState({itemsExpanded: !this.state.itemsExpanded});
  }

  private toggleSelected(item : IFacetItem) : void {
    this.props.onToggle(item);
  }

  private renderDeleteSelectionButton() : JSX.Element | null {
	let showDeleteButton : boolean = false;

    this.props.facet.items.map((item : IFacetItem) => { if(item.selected) { showDeleteButton = true } });

    if (showDeleteButton && this.state.facetExpanded) {
      return (
        <a href="#" className="facet__clear facet__items-delete-selection" onClick={this.clearFacet}>Verwijder selectie</a>
      );
    } else {
	  return null;
	}
  }

  private renderItems() : JSX.Element | null {
    if (!this.state.facetExpanded) {
      return null;
    }

	const showItems: number = this.state.itemsExpanded ? Infinity : SHOW_ITEMS_WHEN_COLLAPSED;
    return (
      <ol className="facet__items">
        {this.props.facet.items.slice(0, showItems).map((item : IFacetItem) => this.renderItem(item))}
      </ol>
    );
  }

  private renderItem(item : IFacetItem) : JSX.Element {
    const inputName : string = `facet-${this.props.facet.key}-${item.key}`;
    let itemHasResults : boolean = typeof(item.count) === "number" && item.count > 0;
    return (
      <li className="facet__item">
        <input type="checkbox" id={inputName} name={this.props.facet.key} value={item.key} checked={item.selected} onChange={this.toggleSelected.bind(this, item)} disabled={!itemHasResults} />
        <label htmlFor={inputName} className={itemHasResults ? "" : "greyed_out"} >
		  {this.props.facet.key === "vestiging" ? item.title.replace(/Oostendorp Auto|Oostendorp Scholten/g,'') : item.title}
          {itemHasResults ? <span className="facet__item_count">({item.count})</span> : ""}
        </label>
      </li>
    );
  }

  private renderItemsExpandButton() : JSX.Element | null {
    if (!this.props.showExpander) {
      return null;
    }
    if (!this.state.facetExpanded) {
      return null;
    }
    if (this.props.facet.items.length <= SHOW_ITEMS_WHEN_COLLAPSED) {
      return null;
    }

    if (this.state.itemsExpanded) {
      return (
        <a href="#" className="facet__items-expander facet__items-expander--expanded" onClick={this.toggleItemsExpanded}>Toon minder</a>
      );
    } else {
      return (
        <a href="#" className="facet__items-expander facet__items-expander--collapsed" onClick={this.toggleItemsExpanded}>Toon meer</a>
      );
    }
  }

  private clearFacet(event : any) : void {
	this.props.facet.items.forEach(item => { this.props.clear(this.props.facet, item) })
  }

}
