import $ from 'jquery';

const $tabs = $('.detail-tabs');

if ($tabs.length) {
  $tabs.find('[role="tablist"] button').on('click', e => {
    const $button = $(e.currentTarget);
    const target = $button.attr('aria-controls') as string;

    // Show corresponding tab
    $tabs.find('.detail-tabs__tab').attr('hidden', 'hidden');
    $(`#${target}`).removeAttr('hidden');

    // Make button active
    $tabs.find('button').removeAttr('aria-selected');
    $button.attr('aria-selected', 'true');
  });
}
