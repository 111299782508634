import React from 'react';
import $ from 'jquery';

import {__DEV__} from "../../constants";
import { getFavorites, IFavorite, removeFavorite } from "../../car-detail/car-favorites";
import Car, { ICar, ICarProperty } from './car';

const ENDPOINT = __DEV__ ? "../../api/vehicles.json" : "/autodata.htm";


interface ICarFavoritesState {
  cars : ICar[];
  loading : boolean;
}

export default class CarFavorites extends React.Component<{}, ICarFavoritesState> {
  constructor(props : {}) {
    super(props);

    this.removeFavorite = this.removeFavorite.bind(this);

    this.state = {
      cars: [],
      loading: false,
    };
  }

  public componentDidMount() {
    this.fetchCars();
  }

  public render() : JSX.Element {
    return (
      <div className="block-vehicle">
        {this.renderCars()}
      </div>
    )
  }

  private renderCars() : JSX.Element {
    if (this.state.loading) {
      return (
        <div>
          <h2><span>Favorieten</span></h2>
          <div>Laden...</div>
        </div>
      );
    }

    if (this.state.cars.length === 0) {
      return (
        <div className="tr-segment__content">
          <h2><span>U heeft nog geen favoriete auto's</span></h2>
          <p>
            Creëer een handig overzicht door jouw favoriete auto's op te slaan.
          </p>
        </div>
      );
    }

    return (
      <div>
        <h2><span>Favorieten ({this.state.cars.length})</span></h2>
        <div className="wrapper">
          {this.state.cars.map((car : ICar) => <Car car={car} onRemove={this.removeFavorite} />)}
        </div>
      </div>
    );
  }

  private fetchCars() : void {
    const favorites : IFavorite[] = getFavorites();

    $.getJSON(
      ENDPOINT,
      {
        identifier: favorites.map((favorite) => favorite.id),
      },
    )
    .done((response : any) => {
      const cars : ICar[] = response.vehicles.map((vehicle : any) => {
        const properties : ICarProperty[] = [];
        Object.keys(vehicle).filter((category) => typeof(vehicle[category]) === "object").map((category) => {
          Object.keys(vehicle[category]).map((k) => {
            properties.push({
              category,
              ...vehicle[category][k]
            })
          });
        });

        return {
          id: vehicle.voertuignrHexon,
          image : vehicle.image,
          title: vehicle.title,
          subtitle: vehicle.subtitle,
          fromPrice: vehicle.fromPrice,
          offerPrice: vehicle.offerPrice,
          discount: vehicle.discount,
          vestiging: vehicle.vestiging,
          url: vehicle.url,
          properties : properties,
        };
      });

      this.setState({
        cars,
        loading: false,
      });
    })
    .fail((e, f) => {
      console.warn("Failed to fetch vehicles", e, f);
      this.setState({
        cars: [],
        loading: false,
      });
    });
  }

  private removeFavorite(car : ICar) : void {
    removeFavorite(car.id);

    this.setState({
      cars: this.state.cars.filter((c) => c.id !== car.id),
    })
  }
}
