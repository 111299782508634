import React from 'react';
import {__DEV__} from '../../constants';

const IMAGE_DOMAIN : string = __DEV__ ? "https://oostendorp-edit.devel.gxsoftware.com/" : "";

export interface ICarProperty {
  category : string;
  name : string;
  label : string;
  value : string | boolean;
}

export interface ICar {
  id : string;
  image : string;
  title: string;
  subtitle : string;
  fromPrice : string;
  offerPrice : string;
  discount : string;
  leasePrice? : string;
  url? : string;
  vestiging : string;
  properties : ICarProperty[];
}

interface ICarProps {
  car : ICar;
  compact : boolean;
  onRemove : (car : ICar) => void;
}

export function carHasProp(car : ICar, prop : string) : boolean {
  return car.properties && car.properties.some((p) => p.name === prop);
}

export function carProp(car : ICar, prop : string) : string | boolean | null {
  const property : ICarProperty | undefined = car.properties.find((p) => p.name === prop);
  if (!property) {
    return null;
  }
  return property.value;
}

export function commonCarProps(cars : ICar[], category : string, options : {differencesOnly : boolean}) : ICarProperty[] {
  let props : ICarProperty[] = [];
  cars.map((car : ICar) => {
    car.properties.filter((property : ICarProperty) => property.category === category).map((property : ICarProperty) => {
      if (!props.some((props : ICarProperty) => props.name === property.name)) {
        props.push(property);
      }
    });
  });

  if (options.differencesOnly) {
    props = props.filter((prop : ICarProperty) => {
      const values : Array<string | boolean | null> = cars.map((car : ICar) => carProp(car, prop.name));
      return !values.every((value : string | boolean | null) => value === values[0]);
    });
  }

  return props;
}

export default class Car extends React.Component<ICarProps> {
  static defaultProps = {
    compact: false,
  }

  constructor(props : ICarProps) {
    super(props);

    this.remove = this.remove.bind(this);
  }

  public render() : JSX.Element {
    return (
      <a href={this.props.car.url} title={`${this.props.car.title} ${this.props.car.subtitle}`}>
        <div className="item contenttype--vehiclemediaitem car-offer">
          <div className="car-offer__thumbnail">
            <img src={`${IMAGE_DOMAIN}${this.props.car.image}`} alt={`${this.props.car.title} ${this.props.car.subtitle}`} />
          </div>

          <div className="car-offer__name">
            <p className="car-offer__title">
              {this.props.car.title}
            </p>

            {this.props.car.subtitle ? <p className="car-offer__subtitle">
              {this.props.car.subtitle}
            </p> : null}
          </div>

          <div className="car-offer__cost">
            {this.props.car.offerPrice ? <p className="car-offer__price"><span dangerouslySetInnerHTML={{__html: this.props.car.offerPrice.replace("&amp;", "&")}} /></p> : null}
            {this.props.car.fromPrice ? <p className="car-offer__fromprice"><span dangerouslySetInnerHTML={{__html: this.props.car.fromPrice.replace("&amp;", "&")}} /></p> : null}
            {this.props.car.leasePrice ? <p className="car-offer__lease">Private lease vanaf <span dangerouslySetInnerHTML={{__html: this.props.car.leasePrice.replace("&amp;", "&")}} /></p> : null}
          </div>

          {this.props.compact ? null : <div className="car-offer__properties">
            {carHasProp(this.props.car, "bouwjaar") ? <span className="car-offer__property">{carProp(this.props.car, "bouwjaar")}</span> : null}
            {carHasProp(this.props.car, "tellerstand") ? <span className="car-offer__property">{carProp(this.props.car, "tellerstand")} km</span> : null}
            {carHasProp(this.props.car, "transmissie") ? <span className="car-offer__property">{carProp(this.props.car, "transmissie")}</span> : null}
          </div>}

          {this.props.car.vestiging ? <div className="car-offer__location">
            {this.props.car.vestiging}
          </div> : null}

          <button type="button" className="car-offer__delete" onClick={this.remove} title="Verwijder"></button>
        </div>
      </a>
    )
  }

  private remove(event : any) : void {
    event.preventDefault();
    this.props.onRemove(this.props.car);
  }
}
