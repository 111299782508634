import Swiper, { Navigation, Pagination, Lazy } from 'swiper';

document.querySelectorAll('.models-carrousel').forEach(carousel => {
  const swiperContainer: HTMLElement | null = carousel.querySelector('.swiper');
  const nextEl: HTMLElement | null = carousel.querySelector('.swiper-button-next');
  const prevEl: HTMLElement | null = carousel.querySelector('.swiper-button-prev');

  if (!swiperContainer || !nextEl || !prevEl) {
    return;
  }

  new Swiper(swiperContainer, {
    modules: [Navigation, Pagination, Lazy],
    // Disable preloading of all images
    preloadImages: false,
    // Enable lazy loading
    lazy: {
       loadPrevNext: true,
       loadPrevNextAmount: 2,
     },
    rewind: true,
    slidesPerView: 1,
    spaceBetween: 20,
    navigation: {
      nextEl,
      prevEl,
    },
    breakpoints: {
      640: {
        slidesPerView: 2,
      },
      767: {
        slidesPerView: 5,
      },
    },

  });
});
