import $ from 'jquery';

const days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
const txtOpen = 'Vandaag open: ';
const txtOpenAfter = ' u';
const txtClose = 'Vandaag gesloten';

$('.opening-hours__toggle').on('click', ({currentTarget}) => {
  const $item = $(<HTMLElement> currentTarget.parentElement);
  $item.toggleClass('opening-hours--open');
  $item.find('.opening-hours__body').slideToggle(250);
});

$('.opening-hours').each(function() {
  const date = new Date(Date.now());
  const strWeekDay = days[date.getDay()];
  const strOpeningHours = $(this).find('.' + strWeekDay).find('.time').text();
  const $buttonContent = $(this).find('.opening-hours__toggle span');

  if(strOpeningHours == 'gesloten') {
    $buttonContent.text(txtClose);
  } else {
    $buttonContent.text(txtOpen + strOpeningHours + txtOpenAfter);
  }
});
