import $ from 'jquery';

export default function Navigation() {
  $(document).ready(function () {
    var navigationActive = false;

    //Add white menu background and dynamic selector element
    $('.header-wrapper').append(
      "<div class='navigation__selector'></div><div class='navigation__background'></div>",
    );

    //declare some parameters
    var $navigation__background = $('.navigation__background');
    var $navigation__selector = $('.navigation__selector');
    var $firstMenuItem = $('.navigation-default #navigation > li:eq(0)');
    var $crumbPath = $('.block-crumb-path');
    var desktop = 1150;
    var mobileMode = false;

    //switch between mobile and desktop logic
    if (window.innerWidth < desktop) {
      $('.sitewide').appendTo('.navigation-default').show();
      mobileMode = true;
    }

    $navigation__selector.css({ width: $firstMenuItem.width() + 'px' });

    $(window).on('resize', function () {
      // Use condition to prevent resize events when keyboard appears on tablet/phone
      if (!(window.innerWidth < desktop && mobileMode == true)) {
        $navigation__selector.css({ width: $firstMenuItem.width() + 'px' });
        $('header.page').removeClass('page--searchmode');
        searchButtonLabelHandler();
      }

      if (window.innerWidth < desktop && mobileMode == false) {
        $('header.page > .block-default').hide();
        $('header.page > .block-favorites-search').hide();
        $('.mobile-highlight').removeClass('mobile-highlight');
        $('.subnavigation-one').hide();
        $('.mobile-menu').removeClass('mobile-menu--hidden');
        $('.mobile-menu__menu').removeClass('mobile-menu__menu--active');
        $('.sitewide').appendTo('.navigation-default');

        mobileMode = true;

      } else if (window.innerWidth >= desktop && mobileMode == true) {
        $('header.page > .block-default').show();
        $('header.page > .block-favorites-search').show();
        $('.subnavigation-one').show();

        $('.sitewide').insertAfter('.block-stickymobile');

        mobileMode = false;
      }
    });

    //Menu button handler
    $('.mobile-menu__menu').on('click', e => {
      e.preventDefault();
      $('header.page > .block-default').slideToggle();
      $('header.page > .block-favorites-search').slideToggle();
      $('.mobile-menu').toggleClass('mobile-menu--hidden');
      $('.mobile-menu__menu').toggleClass('mobile-menu__menu--active');
      $crumbPath.toggle();
    });

    //Top level menu item click handler
    $('.navigation-default #navigation:eq(0) > li > a').on('click', e => {
      e.preventDefault();
      if (window.innerWidth < desktop) {
        const $item = $(<HTMLElement>e.currentTarget.parentElement);
        $('.mobile-highlight')
          .find('.subnavigation-one')
          .slideToggle(250);
        if ($item.hasClass('mobile-highlight')) {
          $('.mobile-highlight').removeClass('mobile-highlight');
        } else {
          $('.mobile-highlight').removeClass('mobile-highlight');
          $item.toggleClass('mobile-highlight');
          $item.find('.subnavigation-one').slideToggle(250);
        }
      }
    });

    //Search button click handler
    $('.header-wrapper .block-search-default').on('click', e => {
      const $item = $(<HTMLElement>e.target);
      if (!$item.hasClass('sitewidesearchfield')) {
        $('header.page').toggleClass('page--searchmode');
        if (mobileMode == false) {
          $('header.page > .block-default').toggle();
        }
        searchButtonLabelHandler();
        if ($('header.page').hasClass('page--searchmode')) {
          $('input[type=search').val('');
          $('.sitewidesearchfield').show();
          $('.sitewidesearchfield').focus();
        } else {
          $('.sitewidesearchfield').hide();
        }
      }
    });

    function searchButtonLabelHandler() {
      var searchLabel = document
        .getElementsByClassName('block-search-default')[0]
        .getElementsByTagName('label')[0];
      if ($('header.page').hasClass('page--searchmode')) {
        searchLabel.innerHTML = 'Sluiten';
      } else {
        searchLabel.innerHTML = 'Zoeken';
      }
    }

    //Menu hover effects
    $('.navigation-default #navigation:eq(0) > li').hover(function () {
      // hover over
      if (window.innerWidth >= desktop) {
        var $selectedLi = $(this);
        if (navigationActive == false) {
          navigationActive = true;
          $navigation__selector.removeClass('navigation__selector--slide');
          $navigation__selector.css({
            left: $selectedLi.position().left + 'px',
            height: '70px',
          });
          $navigation__selector.addClass('navigation__selector--slide');
          $navigation__background.stop();
        } else {
          $navigation__selector.css({
            left: $selectedLi.position().left + 'px',
          });
        }
        $('.navigation-default #navigation > li').removeClass('highlight');
        $selectedLi.addClass('highlight');
        $crumbPath.css('opacity', '0');
        $navigation__background.css({
          height: $selectedLi.find('.subnavigation-one').outerHeight() + 'px',
        });
      }
    }, function () { },//hover out
    );

    $('.navigation-default #navigation:eq(0)').mouseleave(function () {
      if (window.innerWidth > desktop) {
        if (navigationActive == true) {
          navigationActive = false;
          $('.navigation-default #navigation > li').removeClass('highlight');
          $crumbPath.css('opacity', '1');
          $navigation__background.stop();
          setTimeout(function () {
            $navigation__selector.css({ height: '0' });
            $navigation__background.css({ height: '0' });
          }, 100);
        }
      }
    });
  });
}


// // MOBIEL MENU SCROLL VERDWIJNEN 

// // Selecteer de sticky bottom nav-bar, het filtermenu en de button
// const mobileMenu = document.querySelector('.block-stickymobile');
// const menuToggle = document.querySelector('.mobile-menu__menu');
// const filterMenu = document.querySelector('.filter-results');
// const whatsappButton = document.querySelector('#whatsapp-container'); // Selecteer de WhatsApp-knop
// let lastScrollY = window.scrollY;
// let isMenuOpen = false; // Status van het menu

// // Functie om het filtermenu aan te passen op basis van de menu status
// function adjustFilterMenu() {
//   if (isMenuOpen) {
//     filterMenu.style.transform = `translateY(-${mobileMenu.offsetHeight}px)`;
//   } else {
//     filterMenu.style.transform = 'translateY(0)';
//   }
// }

// // Voeg een klikgebeurtenis toe om de status van het menu bij te werken
// menuToggle.addEventListener('click', () => {
//   isMenuOpen = !isMenuOpen; // Toggle de menu-status
//   mobileMenu.style.transform = isMenuOpen ? 'translateY(0)' : ''; // Toon de navigatiebalk als het menu geopend is
//   if (filterMenu) adjustFilterMenu();
// });

// window.addEventListener('scroll', () => {
//   if (!isMenuOpen) {
//     if (window.scrollY === 0) {
//       // Als we bovenaan de pagina zijn, toon het menu
//       mobileMenu.style.transform = 'translateY(0)';
//       if (filterMenu) {
//         adjustFilterMenu(); // Plaats het filtermenu onderaan als het menu niet open is
//       } else {
//         whatsappButton.style.transform = 'translateY(0)'; // Zet de WhatsApp-knop terug naar oorspronkelijke positie
//       }
//     } else if (window.scrollY > lastScrollY) {
//       // Scrollt naar beneden - verberg de navigatiebalk en verschuif filtermenu
//       mobileMenu.style.transform = 'translateY(100%)';
//       if (filterMenu) {
//         filterMenu.style.transform = 'translateY(75%)'; // Verberg het filtermenu naar 75%
//       } else {
//         whatsappButton.style.transform = 'translateY(100%)'; // Verplaats de WhatsApp-knop naar beneden
//       }
//     } else {
//       // Scrollt naar boven - toon de navigatiebalk
//       mobileMenu.style.transform = 'translateY(0)';
//       if (filterMenu) {
//         adjustFilterMenu(); // Plaats het filtermenu onderaan als het menu niet open is
//       } else {
//         whatsappButton.style.transform = 'translateY(0)'; // Zet de WhatsApp-knop terug naar oorspronkelijke positie
//       }
//     }
//   }
//   lastScrollY = window.scrollY;
// });

// // EINDE MOBIEL MENU SCROLL 





// ACTIVE MOBILE ITEM  

// Haal alle menu-items op
const menuItems = document.querySelectorAll('.mobile-menu__item a');

// Haal de huidige URL path op
const currentPath = window.location.pathname;

// Loop door de menu-items om de 'active' class toe te voegen
menuItems.forEach(item => {
  // Controleer of het href attribuut overeenkomt met de huidige path
  if (item.getAttribute('href') === currentPath) {
    item.classList.add('active');
  }
});