import Swiper, { Navigation } from 'swiper';

document.querySelectorAll('.people-slider').forEach(carousel => {
  const swiperContainer: HTMLElement | null = carousel.querySelector('.modiv-default');
  const nextEl: HTMLElement | null = carousel.querySelector('.swiper-button-next');
  const prevEl: HTMLElement | null = carousel.querySelector('.swiper-button-prev');

  if (!swiperContainer || !nextEl || !prevEl) {
    return;
  }

  new Swiper(swiperContainer, {
    modules: [Navigation],
    navigation: {
      nextEl,
      prevEl,
    },
    wrapperClass: 'wrapper',
    slideClass: 'contenttype--personmediaitem',
  });

  // Hide navigation when both buttons are disabled (= when there is just 1 person)
  const people: NodeListOf<HTMLElement> | null = document.querySelectorAll('.contenttype--personmediaitem');
  const disabledButtons: NodeListOf<HTMLElement> | null = document.querySelectorAll('.swiper-button-disabled');
  if (people && people.length <= 1 && disabledButtons && disabledButtons.length > 1) {
    disabledButtons.forEach(button => {
      button.style.display = "none";
    });
  }

});
