// @ts-nocheck

import { debounce } from 'debounce';
import React from 'react';
import { ISearchFacet } from './facet';

interface ISearchFacetProps {
  expanded : boolean;
  facet : ISearchFacet;
  onChange : (item : ISearchFacet, q : string) => void;
}

interface IFacetState {
  facetExpaned : boolean;
  q : string;
}

export default class SearchFacet extends React.Component<ISearchFacetProps, IFacetState> {
  private debouncedCallOnChange : any;

  constructor(props : ISearchFacetProps) {
    super(props);

    this.debouncedCallOnChange = debounce(this.callOnChange, 500);

    this.onChange = this.onChange.bind(this);
    this.toggleFacetExpanded = this.toggleFacetExpanded.bind(this);

    this.state = {
      facetExpaned: props.expanded,
      q: props.facet.q,
    };
  }

  public render() : JSX.Element {
    return (
      <div className={`facet facet--search ${this.state.facetExpaned ? "facet--expanded" : "facet--collapsed"}`}>
        <div className="facet__title" onClick={this.toggleFacetExpanded}>{this.props.facet.title}</div>
        {this.renderBody()}
      </div>
    )
  }

  private renderBody() : JSX.Element | null {
    if (!this.state.facetExpaned) {
      return null;
    }

    return (
      <input type="text" value={this.state.q} onChange={this.onChange} />
    );
  }

  private toggleFacetExpanded() : void {
    this.setState({facetExpaned: !this.state.facetExpaned});
  }

  private onChange(event : React.FormEvent<HTMLInputElement>) : void {
    this.setState({q: event.currentTarget.value});
    this.debouncedCallOnChange();
  }

  private callOnChange() : void {
    this.props.onChange(this.props.facet, this.state.q);
  }
}
