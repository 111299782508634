// Reusable observer to show or hide element when certain elements are out of viewport
window.addEventListener('DOMContentLoaded', (event) => {
    if (document.body.classList.contains('page--vdp-lease')) {
        let getCarPreviewImg = document.querySelector(".aanbod-select-carpreview");
        let observerCarPreview = new IntersectionObserver(function (entries) {
            getCarPreviewImg.style.display = !entries[0].isIntersecting ? 'block' : 'none';
        });
        observerCarPreview.observe(document.querySelector(".car-product"));
    }
    if (document.body.classList.contains('page--rd') && document.body.contains(document.querySelector(".vdp-aanbod"))) {
        let getAanbodContent = document.querySelector(".vdp-sticky");
        let observerAanbodContent = new IntersectionObserver(function (entries) {
            getAanbodContent.style.display = !entries[0].isIntersecting ? 'block' : 'none';
        });
        observerAanbodContent.observe(document.querySelector(".vdp-aanbod"));
    }
});

