// import

// Components: Components
import "../components/01-components/accordion/accordion";
import SearchInHeader from "../components/01-components/search/search";
SearchInHeader();
import HeaderSticky from "../components/02-layouts/-old/header/header";
HeaderSticky();
import "../components/01-components/animation/animation";
import "../components/01-components/_hidden/video-header";
import "../components/01-components/rd-button/rd-button";
import "../components/01-components/-old/opening-hours/opening-hours";
import "../components/01-components/-old/sort-options/sort-options";
import "../components/01-components/form/form";
import "../components/01-components/web-messenger/web-messenger";
import "../components/01-components/rd-filter/rd-filter";
import FilterMeerTonen from "../components/01-components/rd-filter/rd-filter--meertonen";
import RdLeesmeer from "../components/01-components/rd-leesmeer/rd-leesmeer";
import "../components/01-components/tooltip/tooltip";
import "../components/01-components/selection-box/selection-box";

FilterMeerTonen();

// Components: layouts
import "../components/02-layouts/-old/_image-popup/image-popup";
import "../components/02-layouts/-old/review/review";
import "../components/02-layouts/-old/lightbox/lightbox";
import "../components/02-layouts/-old/tabs/tabs";
import "../components/02-layouts/-old/vdp-tabs/vdp-tabs";
import "../components/02-layouts/car-compare/car-compare";
import "../components/02-layouts/-old/car-favorites/app";
import "../components/02-layouts/-old/sitewide-search/sitewide-search";
import "../components/02-layouts/sitewidewarning/sitewidewarning";

// Lease aanbod
import VdpLeaseAanbod from "../components/02-layouts/vdp-lease-aanbod/vdp-lease-aanbod";
VdpLeaseAanbod();

// Navigation
import Navigation from "../components/02-layouts/-old/navigation/navigation";
Navigation();

// Sliders
// TODO: replace all sliders and remove these
// import SwiperJS from "../components/01-components/_swiperjs/swiperjs";
// SwiperJS();
import "../components/01-components/-old/sliders/slider-homepage";
import "../components/01-components/-old/sliders/slider-models";
import "../components/01-components/-old/sliders/slider-PLP";
import "../components/01-components/-old/sliders/slider-VDP";
import "../components/01-components/-old/sliders/slider-people";
import "../components/01-components/-old/sliders/slider-skewed";

// Sliders
// WITH DATASRC
import "../components/01-components/sliders-src/slider-src-homepage";
import "../components/01-components/sliders-src/slider-src-models";
import SliderSrcPlp from "../components/01-components/sliders-src/slider-src-PLP";
import sliderSrcPlpRd from "../components/01-components/sliders-src/slider-src-plp-rd";

import "../components/01-components/sliders-src/slider-src-VDP";
import "../components/01-components/sliders-src/slider-src-people";
import "../components/01-components/sliders-src/slider-src-skewed";

// Rd-sliders with DATASRC
import "../components/01-components/rd-sliders/rd-sliders--vdp";
import "../components/01-components/rd-sliders/rd-sliders--layout";
import "../components/01-components/rd-sliders/rd-sliders--articles";
import "../components/01-components/rd-sliders/rd-sliders--modellen";
import "../components/01-components/rd-sliders/rd-sliders--home";

// React
import "../components/02-layouts/hero-react/app";
import vehicleSearch from "../components/02-layouts/vehicle-search/app";
vehicleSearch();

// TODO: Temporary placement
SliderSrcPlp();
sliderSrcPlpRd();
// TODO: Temporary placement animation sequence
import "../components/01-components/_hidden/home";
