import h from 'hyperscript';
import React from 'react';
import ReactDOM from 'react-dom';
import CarCompare from '../car-compare/car-compare';

interface ICompare {
  id : string;
}

let compares : ICompare[] = []
let overlay : HTMLDivElement | undefined;
let popup : HTMLButtonElement | undefined;

export function getCompares() : ICompare[] {
  return compares;
}

export function isCompare(id : string) {
  return compares.findIndex((compare) => compare.id === id) !== -1;
}

export function toggleCompare(id : string) {
  isCompare(id) ? removeCompare(id) : addCompare(id);
  return isCompare(id);
}

function addCompare(id : string) {
  compares.push({id});
  renderPopup();
}

export function removeCompare(id : string) {
  compares = compares.filter((compare) => compare.id !== id);
  renderPopup();
}

function renderPopup() {
  if (!compares.length ) {
    if (popup) {
      popup.remove();
      popup = undefined;
    }
    return;
  }

  if (!popup) {
    popup = h("button", {className: "car-compare-popup"});
    const container = document.querySelector("body");
    if (!container) {
      return;
    }

    container.appendChild(popup);
    popup.addEventListener("click", showOverlay);
  }

  popup.innerHTML = `Vergelijk ${compares.length} ${compares.length === 1 ? "voertuig" : "voertuigen"}`;
}

function showOverlay() {
  if (overlay) {
    overlay.remove();
  }

  overlay = h("div", {className: "car-compare-overlay"});
  const container = document.querySelector("body");
  if (!container) {
    return;
  }

  container.classList.add("car-compare-overlay-open");
  container.appendChild(overlay);

  ReactDOM.render(
    React.createElement(CarCompare),
    overlay
  );
}

renderPopup();
