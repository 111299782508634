import $ from 'jquery';
import React from 'react';

import {
  Facet,
  IDropdownFacet,
  IFacetItem,
  IFilterFacet,
  IRangeFacet,
  ISearchFacet,
  ISelectFacet
} from '../vehicle-search/facet';
import FilterFacet from '../vehicle-search/filter-facet';
import SelectFacet from '../vehicle-search/select-facet';
import DropdownFacet from '../vehicle-search/dropdown-facet';

interface IHeroProps {
  facetsEndpoint: string | null;
  textInputText: string | null;
  textResults: string | null;
}

interface IHeroState {
  facets: Facet[];
  firstRender: boolean;
  kindNew: boolean;
  kindOccassion: boolean;
  loading: boolean;
  count?: number;
  q: string;
}

export default class Hero extends React.Component<IHeroProps, IHeroState> {
  constructor(props: IHeroProps) {
    super(props);

    this.toggleFacetItem = this.toggleFacetItem.bind(this);
    this.toggleKindNew = this.toggleKindNew.bind(this);
    this.toggleKindOccasion = this.toggleKindOccasion.bind(this);
    this.updateQ = this.updateQ.bind(this);

    this.state = {
      facets: [],
      firstRender: true,
      kindNew: true,
      kindOccassion: true,
      loading: true,
      q: "",
    };
  }

  public componentDidMount(): void {
    this.fetchFacets();
  }

  public render(): JSX.Element {
    if (this.state.loading && !this.state.facets.length) {
      return <div>Laden...</div>;
    }

    const soortFacet: IFilterFacet | undefined = this.state.facets.find((facet: Facet) => facet.key === "soort") as IFilterFacet;

    const facetKeys: string[] = ["merk", "model"];
    const facets: Facet[] = this.state.facets.filter((facet: Facet) => facetKeys.indexOf(facet.key) > -1);

    return (
      <div id="facets-home-vehicle__form-components">
        <div id="facets-home-vehicle__form-block">
          <div>
            {soortFacet ? <div id="facets-home-vehicle__form-checkboxes">
                <FilterFacet facet={soortFacet} expanded={true} onToggle={this.toggleFacetItem} />
              </div> : null}
            <div id="facets-home-vehicle__form-searchbox">
              <input type="text" id="querysearch-input-homepage" name="query" placeholder={this.inputText()} value={this.state.q} onChange={this.updateQ} autoComplete="off" />
            </div>
            <div className="filter-content-block" id="facets-home-vehicle__form-facets">
              <div className="filter-content-block">
                <div className="showmore__item-left">
                  {facets.map((facet: Facet) => {
                        switch (facet.type) {
                          case "filter":
                            return <FilterFacet key={`facet-${facet.key}`} facet={facet} expanded={false}
                                                onToggle={this.toggleFacetItem}/>;
                          case "select":
                            return <SelectFacet key={`facet-${facet.key}`} facet={facet} expanded={false}
                                                  onToggle={this.selectFacetItem.bind(this, facet)}/>;
                          case "dropdown":
                            return <DropdownFacet key={`facet-${facet.key}`} facet={facet} showExpander={true}
                                               onToggle={this.dropdownFacetItem.bind(this, facet)}/>;
                        }
                      }
                  )}
                </div>
              </div>
            </div>
            <div>
              <button type="submit">{this.resultsText()}</button>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private inputText(): string {
    return this.props.textInputText || "Zoek binnen de voorraad";
  }

  private resultsText(): string {
    const txt: string = this.props.textResults || "Toon {#} resultaten";
    return txt.replace("{#}", (this.state.count || 0).toString());
  }

  private toggleKindNew(): void {
    this.setState({ kindNew: !this.state.kindNew });
  }

  private toggleKindOccasion(): void {
    this.setState({ kindOccassion: !this.state.kindOccassion });
  }

  private updateQ(event: React.FormEvent<HTMLInputElement>): void {
    this.setState({ q: event.currentTarget.value }, this.fetchFacets);
  }

  private apiParams(state: IHeroState): {} {
    let params: any = {};

    state.facets.forEach((facet: Facet) => {
      switch (facet.type) {
        case "filter":
          if (facet.items.some((item: IFacetItem) => item.selected)) {
            params[facet.key] = facet.items.filter((item: IFacetItem) => item.selected).map((item: IFacetItem) => item.key);
          }
          break;
        case "select":
          let paramname: string = facet.key;
          if (facet.items.some((item: IFacetItem) => item.selected)) {
            params[paramname] = facet.items.filter((item: IFacetItem) => item.selected).map((item: IFacetItem) => item.key);
          }
          break;
        case "dropdown":
          let facetname: string = facet.key;
          if (facet.items.some((item: IFacetItem) => item.selected)) {
            params[facetname] = facet.items.filter((item: IFacetItem) => item.selected).map((item: IFacetItem) => item.key);
          }
          break;
        case "range":
          if (facet.min !== null) {
            params[`${facet.key}.min`] = facet.min;
          }
          if (facet.max !== null) {
            params[`${facet.key}.max`] = facet.max;
          }
          break;
        case "search":
          if (facet.q.length > 0) {
            params[facet.key] = facet.q;
          }
          break;
      }
    });

    if (state.q) {
      params['query'] = state.q;
    }
    return params;
  }

  private fetchFacets() {
    this.setState({
      loading: true,
    });

    $.getJSON(
      this.props.facetsEndpoint || "/",
      $.param(this.apiParams(this.state), true),
    )
      .done((response: any, x, y) => {
        const facets: Facet[] = response.facets.map((facet: any) => {

          return {
            type: facet.technicalFacetName == 'merk' || facet.technicalFacetName == 'model' ? "dropdown" : "filter",
            key: facet.technicalFacetName,
            title: facet.visibleFacetName,
            items: facet["facet-item"]["facetValues"].map((facetValue: any) => {
              return {
                key: facetValue.technicalName,
                title: facetValue.visibleName,
                count: facetValue.count,
                selected: false
              };
            })
          };
        });

        const searchFacet: Facet = {
          type: "search",
          key: "query",
          title: "Zoek auto",
          q: ""
        }
        facets.unshift(searchFacet);

        // Initially select all items of facet soort
        if (this.state.firstRender) {
          const soortFacet: IFilterFacet | undefined = facets.find((facet: Facet) => facet.key === "soort") as IFilterFacet;
          if (soortFacet) {
            soortFacet.items.map((item: IFacetItem) => item.selected = true);
          }
        }

        this.setState({
          count: response.found,
          facets: this.mergeFacetsValues(facets),
          firstRender: false,
          loading: false,
        });
      })
      .fail((e, f) => {
        console.warn("Failed to fetch facets", e, f);
        this.setState({
          count: undefined,
          facets: [],
          loading: false,
        });
      });
  }

  private mergeFacetsValues(facets: Facet[]): Facet[] {
    const mergedFacets: Facet[] = [];

    facets.forEach((facet: Facet) => {
      const currentFacet: Facet | undefined = this.state.facets.find((c: Facet) => c.key === facet.key);
      const newFacet: Facet = { ...facet };

      if (currentFacet) {
        switch (newFacet.type) {
          case "filter":
            newFacet.items.forEach((item: IFacetItem) => {
              const currentItem: IFacetItem | undefined = (currentFacet as IFilterFacet).items.find((i: IFacetItem) => i.key === item.key);
              if (currentItem) {
                item.selected = currentItem.selected;
              }
            });
            break;
          case "range":
            newFacet.max = (currentFacet as IRangeFacet).max;
            newFacet.min = (currentFacet as IRangeFacet).min;
            break;
          case "search":
            newFacet.q = (currentFacet as ISearchFacet).q;
            break;
          case "select":
            newFacet.items.forEach((item: IFacetItem) => {
              const currentItem: IFacetItem | undefined = (currentFacet as ISelectFacet).items.find((i: IFacetItem) => i.key === item.key);
              if (currentItem) {
                item.selected = currentItem.selected;
              }
            });
            break;
          case "dropdown":
            newFacet.items.forEach((item: IFacetItem) => {
              const currentItem: IFacetItem | undefined = (currentFacet as IDropdownFacet).items.find((i: IFacetItem) => i.key === item.key);
              if (currentItem) {
                item.selected = currentItem.selected;
              }
            });
            break;
        }
      }

      mergedFacets.push(newFacet);
    });

    return mergedFacets;
  }

  private toggleFacetItem(item: IFacetItem): void {
    item.selected = !item.selected;
    this.setState({ facets: this.state.facets }, this.fetchFacets);
  }

  private selectFacetItem(facet: ISelectFacet, item: IFacetItem): void {
    const selectedItem: string = item.key;
    facet.items.forEach((item: IFacetItem) => {
      if (item.key === selectedItem) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });

    this.setState({ facets: this.state.facets }, this.fetchFacets);
  }
  private dropdownFacetItem(facet: IDropdownFacet, item: IFacetItem): void {
    const selectedItem: string = item.target.value;
    facet.items.forEach((item: IFacetItem) => {
      if (item.key === selectedItem) {
        item.selected = true;
      } else {
        item.selected = false;
      }
    });

    this.setState({ facets: this.state.facets }, this.fetchFacets);
  }
}
