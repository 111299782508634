import $ from 'jquery';
import 'jquery-ui';
import 'jquery-ui/ui/widgets/datepicker.js';

document.addEventListener("DOMContentLoaded", () => {
    $('.iaf-form-holder-inner').prepend(
        '<div class="block-overlay__exit-button"></div>',
    );

    $('.button-overlay').click(function () {
        let prefillCallback: string | undefined = $(this).attr('data-prefillcallback');
        if (prefillCallback) {
            eval(prefillCallback + '();');
        }
        if ($(this).attr('data-form-id')) {
            $('.block-overlay#' + $(this).attr('data-form-id')).fadeToggle();
        } else {
            $('.block-overlay.' + $(this).attr('data-form')).fadeToggle();
        }

    });

    $('.block-overlay').click(function (event) {
        if (
            event.target.classList.contains('block-overlay') ||
            event.target.classList.contains('block-overlay__exit-button')
        ) {
            $(this).fadeToggle();
        }
    });

    $.datepicker.regional['nl'] = {
        closeText: 'Sluiten',
        prevText: 'Vorige',
        nextText: 'Volgende',
        currentText: 'Vandaag',
        monthNames: ['januari', 'februari', 'maart', 'april', 'mei', 'juni',
            'juli', 'augustus', 'september', 'oktober', 'november', 'december'],
        monthNamesShort: ['jan', 'feb', 'maa', 'apr', 'mei', 'jun',
            'jul', 'aug', 'sep', 'okt', 'nov', 'dec'],
        dayNames: ['zondag', 'maandag', 'dinsdag', 'woensdag', 'donderdag', 'vrijdag', 'zaterdag'],
        dayNamesShort: ['zon', 'maa', 'din', 'woe', 'don', 'vri', 'zat'],
        dayNamesMin: ['zo', 'ma', 'di', 'wo', 'do', 'vr', 'za'],
        weekHeader: 'Wk',
        dateFormat: 'yy-mm-dd',
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: ''
    };

    $.datepicker.setDefaults($.datepicker.regional['nl']);

    $('input[type="date"]').each(function () {
        $(this).click(function (event) {
            event.preventDefault();
        });

        $(this).keypress(function (event) {
            if (event.which == 32) {
                event.preventDefault();
            }
        });

        $(this).datepicker({
            changeMonth: true,
            changeYear: true
        });
    });

    // Prefill the id for the other lightbox buttons on the page
    $('.primary__buttons button[data-prefillcallback][data-form]').each(function () {
        let prefillId: string | undefined = $(this).attr('data-prefillcallback')
        let form: string | undefined = $(this).attr('data-form')

        if (prefillId && form) {
            let prefillButton: JQuery = $(`.sticky-header-vdp .sticky-header-end button[data-prefillcallback][data-form="${form}"], .header-buttons-vdp .testdrive-button a[data-prefillcallback][data-form="${form}"]`);
            prefillButton.attr('data-prefillcallback', prefillId);
        }
    });
});