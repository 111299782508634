import Swiper, {Navigation, Pagination, Thumbs, Lazy, Keyboard} from 'swiper';

const swiper_thumbnail = new Swiper(".swiper-thumbnail", {
    modules: [Navigation, Pagination],
    slidesPerView: 3,
    spaceBetween: 8,
    breakpoints: {
        400: {
            slidesPerView: 4,
        },
        520: {
            slidesPerView: 5,
            spaceBetween: 12,
        },
        640: {
            slidesPerView: 6,
            spaceBetween: 16,
        }
    }
})

const swiper = new Swiper('.swiper-main', {
    modules: [Navigation, Pagination, Thumbs, Lazy, Keyboard],
    // Disable preloading of all images
    preloadImages: true,
    // Enable lazy loading
    lazy: {
        loadPrevNext: true,
        loadPrevNextAmount: 2,
    },
    keyboard: {
        enabled: true,
    },
    loop: false,
    pagination: {
        el: '.swiper-pagination',
        type: 'fraction',
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    thumbs: {
        swiper: swiper_thumbnail,
    },
})


if (document.querySelector('.car-slider-vdp')) {
    const modal0 = document.querySelector(".modal-sl");
    const modalcontent0 = document.querySelector(".modal-sl-c");
    const trigger = document.querySelector(".btn");
    const closeButton = document.querySelector(".close-button");

    function toggleModal() {
        modal0.classList.toggle("show-modal-sl");
        modalcontent0.classList.toggle("show-modal-sl-c");
    }

    function windowOnClick(event) {
        if (event.target === modal0) {
            toggleModal();
        }
    }

    function removeModal() {
        modal0.classList.remove("show-modal-sl");
        modalcontent0.classList.remove("show-modal-sl-c");
    }

    trigger.addEventListener("click", toggleModal);
    window.addEventListener("click", windowOnClick);
    closeButton.addEventListener("click", removeModal);


    function popFirstImg() {
        // Populate First content image
        let getImg = document.querySelectorAll('.swiper-main > .swiper-wrapper > .swiper-slide > img.swiper-lazy')[0].src;
        let placeholderImage = document.querySelector('.vdp-slider-placeholder').src = getImg;
    }

    window.addEventListener("DOMContentLoaded", () => {
        // Populate Total slides counter
        const getTotalSlides = document.querySelector('.swiper-pagination-total').textContent;
        let pushTotalSlides = document.querySelector('.pushSlideTotal');
        pushTotalSlides.textContent += getTotalSlides;
        // Populate Total slides
        popFirstImg();
    });
}