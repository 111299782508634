import $ from "jquery";

export default function VdpLeaseAanbod() {

    // check for body class
    window.addEventListener('DOMContentLoaded', (event) => {
        if (document.body.classList.contains('page--vdp-lease')) {

            function updateSummary() {
                let selectedCarData = getSelectedCarData();
                $.ajax({
                    url: "/web/autodisk",
                    method: "POST",
                    type: "POST",
                    contentType: "application/json",
                    data: JSON.stringify(selectedCarData),
                    headers: {
                        "X-Session-Verify": getCookie("X-Session-Verify1"),
                        "X-CSRF-Token": csrfProtection.getCsrfToken()
                    },
                    success: function (data) {
                        fillSummary(data);
                        fillForm(data, selectedCarData);
                    },
                    error: function (xhr, e) {
                        console.log(xhr.status);
                    }
                });
            }

            // Init prepayment
            if (document.querySelector("input[name='vooruitbetaling']")) {
                document.querySelector("input[name='vooruitbetaling']").addEventListener('change', event => {
                    let prepaymentElement = event.target;
                    if (prepaymentElement.value > +prepaymentElement.getAttribute('max')) {
                        prepaymentElement.value = +prepaymentElement.getAttribute('max');
                    }
                });
                document.querySelector(".vooruitbetaling-berekening .ic-info-delete").addEventListener('click', event => {
                    event.target.parentElement.querySelector("input").value = '';
                    updateSummary();
                });
            }

            // Init color selection
            document.querySelectorAll('.car-product_color-swatch').forEach(item => {
                item.addEventListener('click', () => {
                    let selectedCarId = document.querySelector('[selected][data-autoid]')?.dataset.autoid;
                    let selectedKilometers = document.querySelector(`[data-for-autoid='${selectedCarId}'] [selected][data-kilometers]`)?.dataset.kilometers;
                    let selectedDuration = document.querySelector(`[data-for-autoid='${selectedCarId}'] [selected][data-looptijd]`)?.dataset.looptijd;
                    // Deselect previous
                    let previousSelectedCarColor = document.querySelector(`[data-for-autoid='${selectedCarId}'] .car-product_color-swatch[selected]`)?.dataset.lakOptieCode;
                    document.querySelectorAll(`[data-for-autoid='${selectedCarId}'] [data-lak-optie-code='${previousSelectedCarColor}']`).forEach(item => item.removeAttribute('selected'));
                    document.querySelectorAll(`[data-for-autoid='${selectedCarId}'] [data-for-lak-optie-code='${previousSelectedCarColor}']`).forEach(item => item.setAttribute('hidden', ''));
                    // Select new
                    let selectedCarColorCode = item.dataset.lakOptieCode;
                    document.querySelectorAll(`[data-for-autoid='${selectedCarId}'] [data-lak-optie-code='${selectedCarColorCode}']`).forEach(item => item.setAttribute('selected', ''));
                    document.querySelectorAll(`[data-for-autoid='${selectedCarId}'] [data-for-lak-optie-code='${selectedCarColorCode}']:not([data-for-kilometers]):not([data-for-looptijd])`).forEach(item => item.removeAttribute('hidden'));
                    document.querySelectorAll(`[data-for-autoid='${selectedCarId}'] [data-for-lak-optie-code='${selectedCarColorCode}'][data-for-kilometers='${selectedKilometers}'][data-for-looptijd='${selectedDuration}']`).forEach(item => item.removeAttribute('hidden'));
                });
            });


            // Init summary update
            if (document.querySelector(".aanbod-select_sidebar-content")) {
                document.querySelectorAll(".car-product_color-swatch").forEach(el => el.addEventListener('click', updateSummary));
                document.querySelectorAll(".selection-box").forEach(el => el.addEventListener('click', updateSummary));
                document.querySelector("input[name='vooruitbetaling']")?.addEventListener('change',updateSummary);


            }


            // Add selected autoid listener to determine visibilty other items
            document.querySelectorAll('[data-autoid]').forEach(select => {
                select.addEventListener('click', () => {
                    let selectedCarId = select.dataset.autoid;
                    // Hide others
                    document.querySelectorAll(`[data-for-autoid]:not([data-for-autoid='${selectedCarId}'])`).forEach(item => {
                        item.setAttribute('hidden', '');
                    });
                    // Show new
                    document.querySelectorAll(`[data-for-autoid='${selectedCarId}']`).forEach(item => {
                        item.removeAttribute('hidden');
                    });
                });
            });

            // Add selected looptijd listener to determine visibilty other items
            document.querySelectorAll('[data-looptijd]').forEach(select => {
                select.addEventListener('click', () => {
                    let selectedCarId = document.querySelector("[selected][data-autoid]")?.dataset.autoid;
                    let selectedCarColor = document.querySelector(`[data-for-autoid='${selectedCarId}'] [selected][data-lak-optie-code]`)?.dataset.lakOptieCode;
                    let selectedKilometers = document.querySelector(`[data-for-autoid='${selectedCarId}'] [selected][data-kilometers]`)?.dataset.kilometers;
                    let selectedDuration = select.dataset.looptijd;
                    // Hide others
                    document.querySelectorAll(`[data-for-autoid='${selectedCarId}'] [data-for-looptijd]:not([data-for-looptijd='${selectedDuration}'])`).forEach(item => {
                        item.setAttribute('hidden', '');
                    });
                    // Show new
                    document.querySelectorAll(`[data-for-autoid='${selectedCarId}'] [data-for-lak-optie-code='${selectedCarColor}'][data-for-kilometers='${selectedKilometers}'][data-for-looptijd='${selectedDuration}']`).forEach(item => {
                        item.removeAttribute('hidden');
                    });
                    document.querySelectorAll(`[data-for-autoid='${selectedCarId}'] [data-for-lak-optie-code='${selectedCarColor}']:not([data-for-kilometers])[data-for-looptijd='${selectedDuration}']`).forEach(item => {
                        item.removeAttribute('hidden');
                    });
                    document.querySelectorAll(`[data-for-autoid='${selectedCarId}'] :not([data-for-lak-optie-code])[data-for-kilometers='${selectedKilometers}'][data-for-looptijd='${selectedDuration}']`).forEach(item => {
                        item.removeAttribute('hidden');
                    });
                    document.querySelectorAll(`[data-for-autoid='${selectedCarId}'] :not([data-for-lak-optie-code]):not([data-for-kilometers])[data-for-looptijd='${selectedDuration}']`).forEach(item => {
                        item.removeAttribute('hidden');
                    });
                });
            });

            // Add selected kilometers listener to determine visibilty other items
            document.querySelectorAll('[data-kilometers]').forEach(select => {
                select.addEventListener('click', () => {
                    let selectedCarId = document.querySelector("[selected][data-autoid]").dataset.autoid;
                    let selectedCarColor = document.querySelector(`[data-for-autoid='${selectedCarId}'] [selected][data-lak-optie-code]`).dataset.lakOptieCode;
                    let selectedDuration = document.querySelector(`[data-for-autoid='${selectedCarId}'] [selected][data-looptijd]`).dataset.looptijd;
                    let selectedKilometers = select.dataset.kilometers;
                    // Hide others
                    document.querySelectorAll(`[data-for-autoid='${selectedCarId}'] [data-for-kilometers]:not([data-for-kilometers='${selectedKilometers}'])`).forEach(item => {
                        item.setAttribute('hidden', '');
                    });
                    // Show new
                    document.querySelectorAll(`[data-for-autoid='${selectedCarId}'] [data-for-lak-optie-code='${selectedCarColor}'][data-for-looptijd='${selectedDuration}'][data-for-kilometers='${selectedKilometers}']`).forEach(item => {
                        item.removeAttribute('hidden');
                    });
                    document.querySelectorAll(`[data-for-autoid='${selectedCarId}'] [data-for-lak-optie-code='${selectedCarColor}']:not([data-for-looptijd])[data-for-kilometers='${selectedKilometers}']`).forEach(item => {
                        item.removeAttribute('hidden');
                    });
                    document.querySelectorAll(`[data-for-autoid='${selectedCarId}'] :not([data-for-lak-optie-code])[data-for-looptijd='${selectedDuration}'][data-for-kilometers='${selectedKilometers}']`).forEach(item => {
                        item.removeAttribute('hidden');
                    });
                    document.querySelectorAll(`[data-for-autoid='${selectedCarId}'] :not([data-for-lak-optie-code]):not([data-for-looptijd])[data-for-kilometers='${selectedKilometers}']`).forEach(item => {
                        item.removeAttribute('hidden');
                    });
                });
            });

            // Init option price visibilty
            document.querySelectorAll('[data-autoid]').forEach(carVersion => {
                let carId = carVersion.dataset.autoid;
                if(carId == null) {
                    carId = document.querySelector("[data-selected-autoid]")?.dataset.selectedAutoid;
                }

                let selectedCarColor = document.querySelector(`[data-for-autoid='${carId}'] [selected][data-lak-optie-code]`)?.dataset.lakOptieCode;
                let selectedDuration = document.querySelector(`[data-for-autoid='${carId}'] [selected][data-looptijd]`)?.dataset.looptijd;
                let selectedKilometers = document.querySelector(`[data-for-autoid='${carId}'] [selected][data-kilometers]`)?.dataset.kilometers;

                // Hide unselected
                document.querySelectorAll(`[data-for-autoid='${carId}'] [data-for-kilometers]:not([data-for-kilometers='${selectedKilometers}'])`).forEach(item => {
                    item.setAttribute('hidden', '');
                });
                document.querySelectorAll(`[data-for-autoid='${carId}'] [data-for-looptijd]:not([data-for-looptijd='${selectedDuration}'])`).forEach(item => {
                    item.setAttribute('hidden', '');
                });
                // Show selected
                document.querySelectorAll(`[data-for-autoid='${carId}'] [data-for-lak-optie-code='${selectedCarColor}'][data-for-looptijd='${selectedDuration}'][data-for-kilometers='${selectedKilometers}']`).forEach(item => {
                    item.removeAttribute('hidden');
                });
                document.querySelectorAll(`[data-for-autoid='${carId}'] :not([data-for-lak-optie-code])[data-for-looptijd='${selectedDuration}'][data-for-kilometers='${selectedKilometers}']`).forEach(item => {
                    item.removeAttribute('hidden');
                });

            });


            function fillForm(returnedData, selectedCarData) {
                document.querySelector('input[type="hidden"][name="brand"]')?.setAttribute('value', returnedData.brand);
                document.querySelector('input[type="hidden"][name="model"]')?.setAttribute('value', returnedData.model);
                document.querySelector('input[type="hidden"][name="car_id"]')?.setAttribute('value', selectedCarData.carId);
                document.querySelector('input[type="hidden"][name="car_name"]')?.setAttribute('value', returnedData.carVersionName);
                document.querySelector('input[type="hidden"][name="color"]')?.setAttribute('value', returnedData.carColorName);
                document.querySelector('input[type="hidden"][name="duration"]')?.setAttribute('value', selectedCarData.duration);
                document.querySelector('input[type="hidden"][name="mileage"]')?.setAttribute('value', selectedCarData.mileage);
                document.querySelector('input[type="hidden"][name="option_codes"]')?.setAttribute('value', selectedCarData.extraOptionCodes.length > 0 ?
                    selectedCarData.extraOptionCodes.join() + "," + selectedCarData.carColorCode : selectedCarData.carColorCode);
                document.querySelector('input[type="hidden"][name="option_names"]')?.setAttribute('value',  selectedCarData.extraOptionNames.join());
                document.querySelector('input[type="hidden"][name="prepayment"]')?.setAttribute('value', selectedCarData.prepayment);
                document.querySelector('input[type="hidden"][name="price"]')?.setAttribute('value', returnedData.total);
                let offerCategory = document.querySelector('[data-offer-category]')?.dataset.offerCategory;
                document.querySelector('input[type="hidden"][name="request_type"]')?.setAttribute('value', offerCategory);
            }

            function fillSummary(data) {
                let fuelTypeElement = document.getElementById("fuel_type");
                if (fuelTypeElement && data.fuel) {
                    fuelTypeElement.innerHTML = data.fuel;
                }
                let transmissionTypeElement = document.getElementById("transmission_type");
                if (transmissionTypeElement && data.transmission) {
                    transmissionTypeElement.innerHTML = data.transmission;
                }

                let carVersionNameElement = document.getElementById("car_version_name");
                if (carVersionNameElement && data.carVersionName) {
                    carVersionNameElement.innerHTML = data.carVersionName;
                }
                let carVersionPriceElement = document.getElementById("car_version_price");
                if (carVersionPriceElement && data.carVersionPrice) {
                    carVersionPriceElement.innerHTML = '&euro; ' + data.carVersionPrice;
                }


                let carColorNameElement = document.getElementById("car_color_name");
                if (carColorNameElement && data.carColorName) {
                    carColorNameElement.innerHTML = data.carColorName;
                }


                let carColorPriceElement = document.getElementById("car_color_price");
                if (carColorPriceElement && typeof data.carColorPrice === 'number') {
                    carColorPriceElement.innerHTML = '&euro; ' + data.carColorPrice;
                }

                let durationPriceElement = document.getElementById("duration_price");
                if (durationPriceElement && typeof data.durationPrice === 'number') {
                    durationPriceElement.innerHTML = '&euro; ' + data.durationPrice;
                }
                let durationElement = document.getElementById("duration");
                let selectionBox = document.querySelector('.selection-box[data-looptijd-label][selected]');
                if (durationElement && selectionBox) {
                    let looptijdLabel = selectionBox.getAttribute('data-looptijd-label');
                    if (looptijdLabel !== null){
                        durationElement.innerHTML = looptijdLabel;
                    }
                } else if (durationElement && typeof data.duration === 'number') {
                    durationElement.innerHTML = data.duration;
                }

                let kilometersPriceElement = document.getElementById("kilometers_price");
                if (kilometersPriceElement && typeof data.kilometersPrice === 'number') {
                    kilometersPriceElement.innerHTML = '&euro; ' + data.kilometersPrice;
                }
                let kilometersElement = document.getElementById("kilometers");
                if (kilometersElement && typeof data.kilometers === 'number') {
                    kilometersElement.innerHTML = data.kilometers;
                }

                let moreKilometersPriceElement = document.getElementById("more_kilometers_price");
                if (moreKilometersPriceElement && typeof data.moreKilometersPrice === 'number') {
                    moreKilometersPriceElement.innerHTML = data.moreKilometersPrice;
                }
                let lessKilometersPriceElement = document.getElementById("less_kilometers_price");
                if (lessKilometersPriceElement && typeof data.lessKilometersPrice === 'number') {
                    lessKilometersPriceElement.innerHTML = data.lessKilometersPrice;
                }

                let prepaymentDiscountElement = document.getElementById("prepayment_discount");
                if (prepaymentDiscountElement) {
                    prepaymentDiscountElement.innerHTML = '- &euro; ' + data.prepaymentDiscount;
                }

                let prepaymentInputContainerElement = document.getElementById("prepayment_input_container");
                if (prepaymentInputContainerElement) {
                    prepaymentInputContainerElement.querySelector("#prepayment_discount_copy").innerHTML = '- &euro; ' + data.prepaymentDiscount;
                    if (data.maxPrepayment) {
                        let maxPrepaymentExplanationElement = prepaymentInputContainerElement.querySelector("#max_prepayment_explanation");
                        if (maxPrepaymentExplanationElement) {
                            maxPrepaymentExplanationElement.innerHTML = data.maxPrepayment.toLocaleString('nl');
                        }
                        let prepaymentElement = prepaymentInputContainerElement.querySelector("input");
                        prepaymentElement.setAttribute('max', data.maxPrepayment);
                        prepaymentElement.setAttribute('placeholder', '0 (max. € ' + data.maxPrepayment.toLocaleString('nl') + ')');
                        if (prepaymentElement.value > data.maxPrepayment) {
                            prepaymentElement.value = data.maxPrepayment;
                        }
                    }
                }

                if (data.total) {
                    document.querySelectorAll("#total_price").forEach(totalPriceElement => {
                        totalPriceElement.innerHTML = data.total;
                    })
                }

                let optionTemplateElement = document.getElementById("option_template");
                while (optionTemplateElement.nextElementSibling != null) {
                    optionTemplateElement.nextElementSibling.remove();
                }
                data.extraOptions && data.extraOptions.reverse().forEach(option => {
                    let clone = optionTemplateElement.cloneNode(true);
                    clone.querySelector(".option-name").innerHTML = option.name;
                    clone.querySelector(".option-price").innerHTML = '&euro; ' + option.price;
                    clone.id = "";
                    clone.hidden = false;
                    optionTemplateElement.parentElement.append(clone);
                });
            }

            function getSelectedCarData() {
                let offerCategory = document.querySelector('[data-offer-category]')?.dataset.offerCategory;
                let carId = document.querySelector("[selected][data-autoid]")?.dataset.autoid;
                if(carId == null) {
                     carId = document.querySelector("[data-selected-autoid]")?.dataset.selectedAutoid;
                }

                let duration = document.querySelector(`[data-for-autoid='${carId}'] [selected][data-looptijd]`)?.dataset.looptijd;
                let mileage = document.querySelector(`[data-for-autoid='${carId}'] [selected][data-kilometers]`)?.dataset.kilometers;
                let prepayment = document.querySelector(`input[name='vooruitbetaling']`)?.value;

                let selectedCarColorCode;
                if (document.querySelector(`[data-for-autoid='${carId}'] [selected][data-lak-optie-code]`) !== null) {
                    selectedCarColorCode = document.querySelector(`[data-for-autoid='${carId}'] [selected][data-lak-optie-code]`)?.dataset.lakOptieCode;
                }

                let extraOptionCodes = Array.from(document.querySelectorAll(`[data-for-autoid='${carId}'] [selected][data-extra-optie-code]`).values())
                    .map(it => it.dataset.extraOptieCode);
                let extraOptionNames = Array.from(document.querySelectorAll(`[data-for-autoid='${carId}'] [selected][data-extra-optie-name]`).values())
                    .map(it => it.dataset.extraOptieName);
                return {
                    carId: offerCategory + "-" + carId,
                    duration: duration,
                    mileage: mileage,
                    prepayment: prepayment ? prepayment : 0,
                    carColorCode: selectedCarColorCode,
                    extraOptionCodes: extraOptionCodes,
                    extraOptionNames: extraOptionNames,
                };
            }

            function getCookie(cname) {
                let name = cname + "=",
                    decodedCookie = decodeURIComponent(document.cookie),
                    ca = decodedCookie.split(';'),
                    i, c;
                for (i = 0, c = ca[i]; i < ca.length; i++) {
                    while (c.charAt(0) == ' ') {
                        c = c.substring(1);
                    }
                    if (c.indexOf(name) == 0) {
                        return c.substring(name.length, c.length);
                    }
                }
                return "";
            }

            // Initial updateSummary to make sure evrything is filled
            updateSummary();
        }
    });
}