import React from 'react';
import ReactDOM from 'react-dom';
import VehicleSearch from './vehicle-search';

export default function vehicleSearch() {

  const facetUrlEl = document.querySelector('.searchresult-facets-json');
  const el = document.querySelector("#searchresult");

  if (facetUrlEl && el) {
    const facetsEndpoint = facetUrlEl.getAttribute("data-facetsurl");
    const carsUrlBase = `${window.location.origin}${window.location.pathname}`;
    const carsEndpoint = el.getAttribute("data-searchurl");
    const carsHTML = el.innerHTML;

    ReactDOM.render(
      React.createElement(VehicleSearch, {
        facetsEndpoint,
        carsEndpoint,
        carsUrlBase,
        carsHTML
      }),
      el
    );
  }
}
