import $ from 'jquery';

const TAB_ACTIVE_CLASS = 'tab--active';

// Add mobile selectbox to HTML
$('.tabbed-component').parent().prepend('<div class="mobile-tab"><select class="mobile-tab__select"></select></div>');

document.querySelectorAll('.tabbed-component').forEach(tabbedComponent => {
  const tabs = tabbedComponent.querySelectorAll('.tab');
  let hasActiveTab = false;

  var tabIndex = 0;
  var activeIndex = 0;

  tabs.forEach(tab => {
    // Add contents to mobile select box
    var tabTitle = tab.getElementsByClassName('gridtitle')[0].innerHTML;
    $(tabbedComponent).prev('.mobile-tab').children('.mobile-tab__select').append('<option value="'+tabIndex+'">'+tabTitle+'</option>');

    // Add indexed class to tab
    tab.classList.add('tab-index-'+tabIndex);
    tab.setAttribute('data-id',tabIndex.toString());

    // check if there is an active tab
    if (tab.classList.contains(TAB_ACTIVE_CLASS)) {
      hasActiveTab = true;
      activeIndex = tabIndex;
    }

    tabIndex++;

    tab.addEventListener('click', () => {

      // remove active class from all tabs
      tabs.forEach(tab => {
        tab.classList.remove(TAB_ACTIVE_CLASS);
      });

      // add active class to current tab
      tab.classList.toggle(TAB_ACTIVE_CLASS);

      // set selected option to mobile select box
      $('.mobile-tab__select option').eq(Number(tab.getAttribute('data-id'))).prop('selected', true);
    })
  })

  // If there is no active tab, set the first tab as the active tab
  if(!hasActiveTab) {
    tabs[0].classList.add(TAB_ACTIVE_CLASS);
  }

  // set selected option to mobile select box
 $('.mobile-tab__select option').eq(activeIndex).prop('selected', true);
});

// Mobile selectbox change handler
$('.mobile-tab__select').change(function() {
  var currentTabIndex =  $(this).val();
  $('.tabbed-component > .tab').removeClass(TAB_ACTIVE_CLASS);
  $('.tab-index-'+currentTabIndex).addClass(TAB_ACTIVE_CLASS);
});
